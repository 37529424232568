@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.statistics-button-card {
  display: none;

  @include xga() {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 140px;
    padding: 10px 20px;
    color: $secondary-text-color;
    background-image: url('../../../../ui/scss/img/StatisticsButton.svg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    flex-shrink: 0;
    gap: 10px 0;

    &:hover {
      color: $secondary-text-color;
    }
  }

  &__title {
    font-size: 1.375rem;
    margin-left: auto;
  }

  &__icon {
    justify-self: center;
    align-self: center;
    font-size: 2.375rem;
  }
}
