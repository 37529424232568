@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-details-statistics-tile {
  background-color: $secondary-tile-color;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  border-radius: 14px;
  padding: 16px 0;
  height: fit-content;

  @include xga() {
    background-color: $secondary-background-color;
    width: fit-content;
    align-items: flex-start;
    padding: 0;
    border-radius: 0;
  }

  &__label {
    text-align: center;
    font-size: 1rem;
    color: $secondary-description-color;

    @include xga() {
      text-align: start;
    }
  }

  &__value {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: $default-label-color;

    @include xga() {
      color: $primary-blue;
      margin-bottom: 5px;
      text-align: start;
    }
  }
}
