@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.create-profile {
  min-height: 100vh;
  background-size: cover;
  background-image: url('../../assets/background-lines-mobile.svg');

  @include xga {
    background-image: url('../../assets/background-lines.svg');
  }

  &__steps {
    padding: 0 14px 40px;

    @include xga {
      padding: 0 54px 40px;
    }
  }

  .create-profile-head {
    padding: 22px 14px 24px;

    @include xga {
      padding: 28px 54px 34px;
    }

    &__title {
      font-weight: 500;
      padding: 0 0 4px;
      margin: 0 0 26px;
      position: relative;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.5rem;

      color: $primary-color;

      &::after {
        left: 50%;
        bottom: 0;
        content: '';
        width: 48px;
        height: 1px;
        opacity: 0.5;
        position: absolute;
        transform: translateX(-50%);

        background-color: $primary-color;
      }

      @include xga {
        margin: 0 0 34px;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    &__subtitle {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.5rem;

      color: $primary-title-color;

      @include xga {
        font-size: 1.75rem;
        line-height: 2.375rem;
      }
    }

    &__description {
      font-weight: 300;
      padding: 14px 0 0;
      line-height: 1.375rem;

      color: $secondary-label-color;

      @include xga {
        padding: 6px 0 0;
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
  }

  &__actions {
    text-align: center;
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    border-radius: 6px;
    padding: 15px 35px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    &--secondary {
      width: 100%;

      color: $primary-color;
      background-color: $secondary-btn-color;
    }

    &:disabled {
      opacity: 0.7;
      cursor: progress;
      color: #00000000;

      > div {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__indicator {
    display: flex;
    padding: 6px 0 30px;
    justify-content: center;

    @include xga {
      padding: 22px 0 44px;
    }
  }

  &__dots {
    --step: 0;

    display: flex;
    width: max-content;
    position: relative;

    &::before {
      top: 0;
      left: 0;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      transition: 0.4s transform ease-in-out;
      transform: translateX(calc(var(--step) * 22px));

      background-color: $primary-color;

      @include xga {
        width: 16px;
        height: 16px;

        transform: translateX(calc(var(--step) * 28px));
      }
    }
  }

  &__dot {
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 0 10px 0 0;
    border-radius: 50%;

    background-color: $secondary-inactive-color;

    &:last-child {
      margin: 0;
    }

    @include xga {
      width: 16px;
      height: 16px;
      margin: 0 12px 0 0;
    }
  }

  .create-profile-card {
    margin: 0 0 28px;
    height: max-content;
    padding: 10px 8px 16px;
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);

    background-color: $secondary-background-color;

    @include xga {
      padding: 10px 16px 16px;
    }

    &__title {
      padding: 0 0 22px;
      font-size: 1.125rem;
      line-height: 1.5rem;

      color: $primary-link-color;

      &--avatar {
        padding: 0 0 8px;

        @include xga {
          padding: 0 0 44px;
        }
      }

      @include xga {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    &__field {
      padding: 0 12px;
    }

    &:last-child {
      margin: 0;
    }

    &__footnote {
      margin: 52px 0 0;
      font-size: 0.75rem;

      color: $primary-description-color;
    }
  }
}
