@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.invoice-details {
  @include xga() {
    width: 900px;
    padding: 27px 27px 0;
  }

  @include wxga() {
    width: 1030px;
  }

  @media print {
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background-color: $primary-card-background;
  }

  &__header {
    display: flex;
    gap: 0 16px;
    padding: 0 0 38px;
    border-bottom: 1px solid $border-default-color;
  }

  &__title {
    font-weight: 300;
    font-size: 2.25rem;

    span {
      font-weight: 500;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }

  &__info {
    font-weight: 300;
    font-size: 1rem;
    color: $secondary-description-color;

    @media print {
      color: $default-label-color;
    }
  }

  &__button {
    border: none;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 15px 35px;
    border-radius: 6px;
    background-color: $primary-color;
    color: $primary-btn-text-color;
    cursor: pointer;

    &--desktop {
      display: none;

      @include xga() {
        display: block;
        align-self: flex-start;
        margin-left: auto;
      }
    }

    &--mobile {
      margin-top: 25px;

      @include xga() {
        display: none;
      }
    }

    &--secondary {
      font-weight: normal;
      background-color: $transparent-color;
      color: $primary-color;
      padding: 0;
      margin: 14px 0 33px;
    }

    @media print {
      display: none;
    }
  }

  &__invoice {
    padding-top: 20px;

    @include xga() {
      display: grid;
      grid: 1fr / 2fr 1fr;
      border-bottom: 1px solid $border-default-color;
    }

    @media print {
      display: grid;
      grid: 1fr / 2fr 1fr;
      border-bottom: 1px solid $border-default-color;
    }
  }

  &__client {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: 500;
    font-size: 1.125rem;
    color: $default-label-color;

    @media print {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  &__value {
    font-weight: 300;
    font-size: 1.125rem;
    color: $secondary-description-color;
    white-space: pre;

    @include xga() {
      font-weight: normal;
    }

    &--status {
      position: relative;
      font-weight: 500;
      color: $secondary-text-color;
      border-radius: 32px;
      padding: 2px 10px 4px 22px;
      font-size: 0.875rem;

      &::before {
        content: '';
        background-color: $primary-card-background;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        top: 11px;
        left: 11px;
      }
    }

    &--pending {
      background-color: $button-color-linkedin;
    }

    &--paid {
      background-color: $secondary-green;
    }

    &--canceled {
      background-color: $status-canceled-color;
    }

    &--price {
      font-weight: 500;
      color: $primary-color;
    }

    @media print {
      color: $default-label-color;
      background-color: $transparent-color;
      font-size: 1rem;
      font-weight: normal;
    }
  }

  &__billing {
    margin: 0 0 33px;

    @include xga() {
      margin: 0 0 20px;
    }
  }

  &__description {
    color: $primary-color;
    font-size: 1.125rem;
    margin: 14px 0;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 0 0 38px;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      border-bottom: none;
    }

    @media print {
      border-bottom: none;
    }
  }

  &__detail {
    display: flex;
    justify-content: space-between;
  }

  &__items {
    display: flex;
    flex-direction: column;
    padding: 20px 0 32px;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      border-bottom: none;
      display: grid;
      grid: 1fr / 1fr 3fr 1fr 1fr;
      padding-top: 12px;
    }

    @media print {
      border-bottom: none;
      display: grid;
      grid: 1fr / 1fr 3fr 1fr 1fr;
      padding-top: 12px;
    }
  }

  &__labels {
    display: none;

    @include xga() {
      display: grid;
      grid: 1fr / 1fr 3fr 1fr 1fr;
      padding-top: 20px;
    }

    @media print {
      display: grid;
      grid: 1fr / 1fr 3fr 1fr 1fr;
      padding-top: 20px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @include xga() {
      .invoice-details__label {
        display: none;
      }
    }

    @media print {
      .invoice-details__label {
        display: none;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }
}

.invoice-modal {
  padding: 8px 11px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @include xga() {
    width: 300px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0 6px;
  }
  &__button {
    border: none;
    font-size: 0.875rem;
    padding: 6px 31px;
    border-radius: 6px;
    background-color: $primary-color;
    color: $primary-btn-text-color;
    cursor: pointer;

    &--secondary {
      background-color: $transparent-color;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}
