@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.settings-my-account {
  @include xga() {
    width: 50%;
  }

  &__form {
    & > div {
      margin-bottom: 20px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include xga {
    }
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin: 36px 0 0;
    border-radius: 6px;
    padding: 15px 35px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    &--secondary {
      width: 100%;

      color: $primary-color;
      background-color: $secondary-btn-color;
    }

    @include xga {
      margin: 26px 0 0;
      padding: 10px 20px;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
