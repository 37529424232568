@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.upload-file {
  display: flex;
  flex-direction: column;

  &__container {
    display: grid;
    border-radius: 16px;
    margin: 0 13px;
    padding: 42px 18px 34px;
    border: 1px dashed $dashed-border-color;

    @include xga() {
      padding: 20px 0;
      margin-bottom: 58px;
      grid: auto / 1fr 1fr;
    }
  }

  &__drop {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    @include xga() {
      flex-shrink: 0;
      padding: 27px 95px;
      border-bottom: none;
    }
  }

  &__manual {
    margin: 30px 0 0;
    padding: 30px 0 0;
    text-align: center;

    border-top: 1px solid $border-default-color;

    @include xga {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid $border-default-color;
    }
  }

  &__title {
    color: $secondary-label-color;
    margin-bottom: 8px;

    &--mobile-hidden {
      display: none;

      @include xga() {
        display: initial;
      }
    }
  }

  &__description {
    color: $tertiary-label-color;
    font-size: 0.875rem;
    text-align: center;
    max-width: 170px;
    margin: 0 auto;
  }

  &__divider {
    color: $tertiary-label-color;
    font-size: 1rem;
    margin: 17px auto;

    &--mobile-hidden {
      display: none;
    }

    @include xga() {
      display: initial;
      margin: 13px 0;

      &--desktop-hidden {
        display: none;
      }
    }
  }

  &__upload {
    display: none;

    @include xga() {
      display: initial;
      color: $tertiary-label-color;

      span {
        cursor: pointer;
        color: $text-btn-color;
      }
    }
  }

  &__uploaded-file {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 200px;
  }

  &__saved-talents {
    width: 100%;
    padding: 24px 16px 0;
  }

  &__button {
    background: $primary-color;
    border-radius: 6px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 0.875rem;
    color: $primary-btn-text-color;
    border: none;
    margin-top: 17px;
    cursor: pointer;

    &--secondary {
      padding: 15px 15px 15px 17px;
      display: flex;
      gap: 0 10px;
      color: $primary-color;
      background-color: $secondary-btn-color;
      align-items: center;
      margin: 0 0 4px;

      i {
        font-size: 0.938rem;
      }

      @include xga() {
        display: none;
      }
    }
  }

  &__label {
    color: $secondary-label-color;
    font-size: 0.875rem;
  }
}
