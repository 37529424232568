@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.talent-preview {
  &__personal-info {
    @include wxga() {
      display: grid;
      grid: repeat(3, auto) / 0fr 0fr;
      gap: 0 17px;
      padding-top: 14px;
      margin-top: 15px;
      border-top: 1px solid $border-default-color;
    }
  }

  &__image {
    width: 100%;
    display: flex;
    height: 342px;
    font-size: 3rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    @include wxga() {
      width: 242px;
      height: 183px;
      grid-row: 1/4;
      grid-column: 1/2;
      border-radius: 4px;

      img {
        object-fit: cover;
      }
    }
  }

  &__name {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: $default-label-color;
    margin: 21px 17px 5px 17px;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 2/3;
      margin: 0;
    }
  }

  &__position {
    font-size: 1rem;
    color: $default-label-color;
    padding-bottom: 24px;
    border-bottom: 1px solid $border-default-color;
    margin: 0 17px;

    @include wxga() {
      grid-row: 2/3;
      grid-column: 2/3;
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
  }

  &__details {
    padding: 20px 9px 0;
  }

  &__detail {
    display: flex;
    justify-content: space-between;
    padding: 16px 6px 16px 8px;
    border-bottom: 1px solid $primary-disabled-color;

    &:last-child {
      border-bottom: none;
    }

    &--desktop-hidden {
      display: none;
    }
  }

  &__label {
    font-size: 1rem;
    color: $description-color;
  }

  &__value {
    font-size: 1rem;
    color: $default-label-color;
  }

  &__section-header {
    font-weight: 500;
    font-size: 1.375rem;
    color: $default-label-color;

    @include wxga() {
      font-size: 0.875rem;
      padding-left: 8px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__badges {
    gap: 8px 7px;
    display: flex;
    flex-wrap: wrap;
    max-width: 256px;
    justify-content: flex-end;
  }

  &__badge {
    padding: 1px 14px;
    background-color: $primary-light-grey;
    color: $primary-color;
    font-weight: 300;
    font-size: 0.875rem;
    border-radius: 2px;
  }

  &__actions {
    display: flex;
    gap: 0 8px;
    margin-top: 35px;
    justify-content: center;

    @include wxga() {
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  &__button {
    padding: 15px 35px;
    font-weight: 500;
    font-size: 1.125rem;
    color: $primary-btn-text-color;
    cursor: pointer;
    background-color: $primary-color;
    border: none;
    border-radius: 6px;

    &--secondary {
      background-color: $default-tile-color;
      color: $primary-color;
    }

    &--save {
      padding: 17px 19px;
      span {
        display: none;
      }

      i {
        font-size: 1.375rem;
      }

      @include wxga() {
        padding: 15px 35px;
        span {
          display: initial;
        }

        i {
          display: none;
        }
      }
    }

    &--backn {
      @include wxga() {
        display: none;
      }
    }
  }

  .cv {
    margin: 39px 17px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $primary-disabled-color;

    @include wxga() {
      display: none;
    }

    &__label {
      font-weight: 500;
      font-size: 1rem;
      color: $default-label-color;
      margin-bottom: 20px;
    }

    &__container {
      display: grid;
      grid: 1fr 1fr / 1fr 0fr;
      border-radius: 12px;
      background-color: $blue-background-color;
      color: $primary-blue;
      padding: 15px 23px 15px 17px;
    }

    &__file-name {
      font-weight: 500;
      font-size: 1rem;
      word-break: break-word;
    }

    &__modified-date {
      color: $blue-label-color;
      font-size: 0.875rem;
    }

    a {
      grid-row: 1/3;
      grid-column: 2/3;
      align-self: center;
      color: $primary-blue;
    }
  }

  .web-links {
    padding: 20px 17px 24px;
    border-bottom: 1px solid $primary-disabled-color;

    @include wxga() {
      grid-row: 3/4;
      grid-column: 2/3;
      border-bottom: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__label {
      font-weight: 500;
      font-size: 1rem;
      color: $default-label-color;
      margin-bottom: 20px;

      @include wxga() {
        display: none;
      }
    }

    &__container {
      display: flex;
      gap: 0 10px;
    }

    &__link {
      display: flex;
      border: none;
      border-radius: 6px;
      padding: 12px 14px;
      font-weight: 500;
      font-size: 1rem;
      gap: 0 10px;
      align-items: center;
      cursor: pointer;

      &--linkedin {
        background-color: $button-background-linkedin;
        color: $button-color-linkedin;

        &:hover {
          color: $button-color-linkedin;
        }
      }

      &--email {
        background-color: $default-tile-color;
        color: $primary-color;
      }
    }
  }

  .detail-cv {
    @include wxga() {
      display: grid;
      grid: 0fr 0fr / 1fr 0fr;
      gap: 0 8px;
    }

    &__file-name {
      color: $primary-blue;
      font-weight: 500;
      font-size: 1rem;
      word-break: break-word;
      text-decoration: underline;
    }

    &__modified-date {
      color: $secondary-label-color;
      font-size: 0.875rem;
      justify-self: end;
      grid-column: 1/3;
    }

    a {
      align-self: center;
      color: $primary-blue;
    }
  }
}
