@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.hr-job-tile {
  .job-tile {
    padding: 22px 16px 22px 12px;

    border-bottom: 1px solid $border-default-color;

    @include xga {
      padding: 32px 38px 32px 34px;
    }

    &__content {
      position: relative;

      @include xga {
        display: flex;
        align-items: center;
      }
    }

    &__image {
      width: 72px;
      height: 60px;
      display: flex;
      overflow: hidden;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      border: 1px solid $border-default-color;

      @include xga {
        margin: 0 10px 0 0;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &:empty {
        position: relative;

        background-color: $default-logo-background-color;

        &::after {
          top: 50%;
          left: 50%;
          width: 8px;
          content: '';
          height: 38px;
          position: absolute;
          transform: translate(-50%, -50%) skew(20deg);

          background-color: $primary-black;
        }
      }
    }

    &__title {
      padding: 8px 0;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.75rem;

      color: $primary-black;

      @include xga {
        padding: 0 0 4px;
      }
    }

    &__details {
      display: flex;
      flex-wrap: wrap;

      .job-tile-detail {
        display: flex;
        padding: 0 28px 0 0;
        line-height: 1.5rem;

        color: $description-color;

        @include xga {
          padding: 0 42px 0 0;
        }

        &__value {
          padding: 0 0 0 14px;

          color: $primary-color;
        }
      }
    }

    &__actions {
      top: 0;
      right: 0;
      display: flex;
      position: absolute;

      @include xga {
        margin-left: auto;
        position: relative;
      }
    }

    &__button {
      width: 32px;
      height: 32px;
      border: none;
      display: flex;
      cursor: pointer;
      margin: 0 0 0 4px;
      font-size: 0.75rem;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      &--edit {
        background-color: $default-tile-color;
        color: $primary-color;
      }

      &--pause {
        background-color: $primary-light-blue;
        color: $primary-text-blue;
      }

      &--archive {
        background-color: $primary-light-red;
        color: $primary-text-red;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        display: none;
      }

      @include xga {
        width: max-content;
        padding: 0 20px;
        font-weight: 500;
        line-height: 1.25rem;

        i {
          display: none;
        }

        span {
          display: inline;
        }
      }
    }
  }
}
