@import 'src/ui/scss/responsiveness';

.messaging-panel {
  $root: &;
  white-space: nowrap;
  overflow: hidden;
  flex-direction: row;
  height: 65vh;
  position: relative;

  .spinner {
    margin: auto;
  }

  &__boxes-wrapper,
  &__messages-wrapper {
    width: 100%;
    height: 100%;
    vertical-align: top;
    transition: transform 0.3s ease;
    display: inline-block;
  }

  @include max-mobile {
    &.thread-selected {
      #{$root}__boxes-wrapper,
      #{$root}__messages-wrapper {
        transform: translateX(-100%);
      }
    }
  }

  @include tablet {
    display: flex;

    &__boxes-wrapper {
      min-width: 33%;
      max-width: 33%;
    }

    &__messages-wrapper {
      margin-left: 10px;
      flex-grow: 1;
      width: auto;
    }
  }

  @include xga {
    height: 60vh;
  }
}
