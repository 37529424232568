@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-upload-file {
  padding: 0 13px;

  &__container {
    margin-top: 43px;
  }

  &__file {
    border: 1px dashed $border-default-color;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 0;

    @include xga() {
      padding: 46px 0;
    }

    input {
      display: none;
    }
  }

  &__divider {
    font-size: 1rem;
    line-height: 22px;
    color: $default-label-color;
    opacity: 0.3;
    margin: 35px 0;
    text-align: center;

    @include xga() {
      margin: 38px 0 13px;
    }

    &--inside-upload {
      display: none;

      @include xga() {
        display: block;
        margin: 12px 0;
      }
    }
  }

  &__link {
    margin-bottom: 53px;

    @include xga() {
      margin-bottom: 203px;
    }
  }

  &__link-title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 22px;
    color: $default-label-color;
  }

  &__button {
    @include xga() {
      display: none;
    }
  }
  &__title {
    display: none;
    text-align: center;

    @include xga() {
      display: block;
      font-size: 1rem;
      line-height: 24px;
      color: $tertiary-label-color;
      margin-bottom: 5px;
      white-space: pre;
    }
  }

  &__upload {
    display: none;

    @include xga() {
      display: block;
      font-size: 0.875rem;
      line-height: 19px;
      color: $default-message-color;

      span {
        color: $default-label-color;
        cursor: pointer;
      }
    }
  }
}
