@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.hr-job-talents {
  .job-talents {
    padding: 0 0 200px;

    @include xga() {
      padding: 0;
      width: max-content;
    }

    &__applications {
      padding: 10px 0 0 0;
    }

    &__application {
      padding: 22px 0;

      border-bottom: 1px solid $border-default-color;

      &:last-child {
        border-bottom: none;
      }
    }

    &__actions {
      text-align: right;
      padding: 32px 16px;

      @include xga {
        padding: 32px 0 0;
      }
    }

    &__button {
      border: none;
      cursor: pointer;
      font-weight: 500;
      margin: 0 0 0 12px;
      border-radius: 6px;
      padding: 15px 35px;
      line-height: 1.5rem;
      font-size: 1.125rem;
      display: inline-block;

      color: $primary-btn-text-color;
      background-color: $primary-color;

      &--secondary {
        color: $primary-color;
        background-color: $secondary-btn-color;
      }
    }

    &__empty {
      padding: 24px 0 0;
      font-size: 0.875rem;
      text-align: center;

      color: $secondary-description-color;
    }
  }
}
