@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.deal-card {
  display: grid;
  grid: repeat(7, auto) / auto 3fr auto;
  gap: 0 9px;
  background-color: $primary-card-background;
  box-shadow: 0 4px 12px $border-default-color;
  border-radius: 8px;
  padding: 14px 11px 6px 7px;

  @include wxga() {
    grid: auto / 0fr 2fr repeat(4, 1fr) auto;
    justify-content: space-between;
    box-shadow: none;
    padding: 21px 12px 27px 0;
    border-bottom: 1px solid $border-default-color;
  }

  &__icon {
    grid-row: 1/3;
    grid-column: 1/2;

    width: 40px;
    height: 40px;

    img {
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    @include wxga() {
      grid-row: 1/2;
    }
  }

  &__info {
    grid-row: 1/4;
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 6px 0;

    @include wxga() {
      grid-row: 1/2;
      display: grid;
      grid: auto auto / 0fr auto;
      gap: 0 7px;
    }
  }

  &__job {
    font-weight: 500;
    font-size: 0.875rem;
    color: $default-label-color;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 1/3;
    }
  }

  &__talent {
    font-weight: 500;
    font-size: 0.875rem;
    color: $primary-color;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }

    @include wxga() {
      grid-row: 2/3;
      grid-column: 1/2;
      white-space: nowrap;
    }
  }

  &__recruiter {
    font-size: 0.875rem;
    color: $primary-description-color;

    span {
      text-decoration-line: underline;
    }

    @include wxga() {
      grid-row: 2/3;
      grid-column: 2/3;

      span {
        white-space: nowrap;
      }
    }
  }

  &__status {
    grid-row: 1/2;
    grid-column: 3/4;
    width: fit-content;
    justify-self: flex-end;

    & > .deal-card__label {
      display: none;
    }

    &--active {
      & > .deal-card__value {
        color: $primary-text-green;
        background-color: $primary-light-green;
        border-radius: 3px;
        padding: 1px 6px;
        font-weight: 500;
      }
    }

    &--paused {
      & > .deal-card__value {
        color: $primary-rating-color;
        background-color: $primary-light-green;
        border-radius: 3px;
        padding: 1px 6px;
        font-weight: 500;
      }
    }

    @include wxga() {
      grid-row: 1/2;
      justify-self: flex-start;

      & > .deal-card__label {
        display: block;
      }

      & > .deal-card__value {
        background-color: $transparent-color;
        padding: 0;
      }
    }
  }

  &__date {
    grid-row: 2/3;
    grid-column: 3/4;

    font-size: 0.875rem;
    color: $description-color;
    justify-self: flex-end;
    margin-top: 6px;

    & > .deal-card__label {
      display: none;

      @include wxga() {
        display: block;
      }
    }

    @include wxga() {
      margin-top: 0;
      grid-row: 1/2;
      grid-column: 4/5;
      justify-self: flex-start;
    }
  }

  &__toggle {
    grid-row: 7/8;
    grid-column: 3/4;

    font-weight: 300;
    font-size: 1rem;
    color: $primary-description-color;
    justify-self: flex-end;
    display: flex;
    align-items: center;

    &--collapsed {
      grid-row: 3/4;
    }

    i {
      margin-left: 4px;
      font-size: 1.375rem;
    }

    @include wxga() {
      display: none;
    }
  }

  &__fee,
  &__invoice {
    display: none;
    margin-top: 6px;

    &--expand {
      display: flex;
    }

    @include wxga() {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__fee {
    grid-row: 4/5;
    grid-column: 2/3;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 5/6;
    }
  }

  &__invoice {
    grid-row: 5/6;
    grid-column: 2/3;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 6/7;
    }
  }

  &__label,
  &__value {
    font-size: 0.875rem;
    color: $default-label-color;
  }

  &__label {
    @include wxga() {
      margin-bottom: 6px;
    }
  }

  &__value {
    @include wxga() {
      color: $description-color;
    }
  }

  &__button {
    display: none;
    font-family: 'Halyard Display', sans-serif;
    grid-row: 6/7;
    grid-column: 2/3;

    border: none;
    border-radius: 6px;
    padding: 6px 7px;
    width: fit-content;
    font-size: 0.875rem;
    color: $primary-color;
    background-color: $tertiary-btn-color;
    margin-top: 10px;
    cursor: pointer;

    &--expand {
      display: block;
    }

    @include wxga() {
      display: flex;
      grid-row: 1/2;
      grid-column: 7/8;
      justify-self: flex-end;
      height: fit-content;
      padding: 10px 20px;
    }
  }
}
