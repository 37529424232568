@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-search-recommendations {
  &__header {
    margin-bottom: 25px;

    @include xga() {
      border-bottom: 1px solid $border-default-color;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include xga() {
      font-weight: 500;
      font-size: 1.125rem;
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
      width: fit-content;
      padding-bottom: 9px;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 1rem;
    color: $secondary-description-color;

    @include xga() {
      display: none;
    }
  }

  &__container {
    display: flex;
    overflow-x: scroll;
    flex-wrap: wrap;

    @include wxga() {
      overflow-x: initial;
    }
  }

  &__navigation {
    display: none;

    @include wxga() {
      display: flex;
      justify-content: flex-end;
      margin: 25px 0 22px 0;
    }
  }

  &__icon {
    font-size: 1.625rem;
    cursor: pointer;

    &--back {
      color: $secondary-label-color;
      margin-right: 11px;
    }

    &--has-previous {
      color: $primary-color;
      transform: rotateY(180deg);
    }

    &--forward {
      color: $secondary-label-color;
      transform: rotateY(180deg);
    }

    &--has-next {
      color: $primary-color;
      transform: initial;
    }
  }
}
