@import './variables.scss';
@import './preferences.scss';

.btn {
  border: none;
  padding: 12px;
  cursor: pointer;
}

.btn-square {
  width: 42px;
  height: 42px;
}

.btn-landing {
  width: 200px !important;
  height: 60px !important;
  border-radius: 40px !important;
  background-color: $landing-button !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $landing-button-hovered !important;
  }
}

.btn-auth {
  color: white !important;
  background-color: $landing-primary-color !important;
  border-radius: $default-border-radius !important;
  &:hover,
  &:focus,
  &:active {
    color: white !important;
    background-color: $landing-primary-color !important;
  }
}

.btn-primary {
  color: rgba(101, 83, 223, 1) !important;
  background-color: rgba(101, 83, 223, 0.1) !important;
}

.btn-linkedin {
  color: rgba(0, 118, 180, 1) !important;
  background-color: rgba(0, 118, 180, 0.1) !important;
  &:hover,
  &:focus,
  &:active {
    color: rgb(0, 119, 181) !important;
  }
}

.btn-twitter {
  color: rgb(29, 161, 242) !important;
  background-color: rgba(29, 161, 242, 0.1) !important;
  &:hover,
  &:focus,
  &:active {
    color: rgb(29, 161, 242) !important;
  }
}

.btn-xing {
  color: wrgba(42, 154, 183, 1) !important;
  background-color: rgba(42, 154, 183, 0.1) !important;
  &:hover,
  &:focus,
  &:active {
    color: rgba(42, 154, 183, 1) !important;
  }
}

.btn-personal {
  color: rgba(62, 62, 239, 1) !important;
  background-color: rgba(62, 62, 239, 0.1) !important;
  &:hover,
  &:focus,
  &:active {
    color: rgba(62, 62, 239, 1) !important;
  }
}

.btn-cancel {
  color: white !important;
  background-color: $danger-color !important;
  border-radius: $default-border-radius !important;
  &:hover,
  &:focus,
  &:active {
    color: white !important;
    background-color: $danger-color-hovered !important;
  }
}

.button,
.btn-primary,
.btn-landing,
.btn-auth,
.btn-cancel {
  &:disabled {
    color: gray !important;
    background-color: $disabled-color !important;
  }
}

.btn-linkedin,
.btn-twitter,
.btn-personal,
.btn-xing {
  padding: 17px;
  &:disabled {
    color: white !important;
    background-color: $media-disabled-color !important;
  }
}
