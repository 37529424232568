@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-benefits-card {
  display: flex;
  border-radius: 6px;
  padding: 12px 8px 0;
  flex-direction: column;
  box-shadow: 0 6px 18px rgba(62, 56, 106, 0.03);

  border: 1px solid $border-default-color;

  @include xga {
    padding: 14px 14px 6px;
  }

  &:last-child {
    margin: 0;
  }

  &__header {
    margin: 0 0 36px;
    padding: 0 0 0 4px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;

    color: $primary-color;
  }

  &__description {
    margin: 0 0 14px;
    font-weight: 300;
    text-align: center;
    line-height: 1.5rem;
    letter-spacing: -0.2px;

    color: $secondary-description-color;

    @include xga() {
      font-size: 1.125rem;
    }
  }

  &__select {
    margin: 0 0 58px;

    @include xga {
      padding: 0 36px;
      margin: 0 0 50px;
    }
  }

  &__benefits {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__benefit-tile {
    padding: 0 4px;
    font-weight: 300;
    border-radius: 2px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0 8px 8px 0;

    color: $primary-color;
    background-color: $primary-light-grey;

    @include xga() {
      margin: 8px 8px 0 0;
    }

    i {
      cursor: pointer;
      margin: 0 7px 0 0;
      font-size: 0.625rem;
    }

    i {
      font-size: 0.625rem;
      color: $secondary-light-grey;
      margin-right: 7px;
    }
  }
}
