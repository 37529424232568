@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

$root: 'base-text-area';

.base-text-area {
  display: flex;
  flex-direction: column;

  &__label {
    order: 1;
    font-weight: 300;
    margin-left: 4px;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    & > sup {
      color: red;
    }
  }

  &__input {
    order: 2;
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    resize: vertical;
    padding: 8px 16px;
    min-height: 210px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $border-default-color;
    font-family: 'Halyard Display', sans-serif;

    &:focus {
      color: $border-active-color;
      border-color: $border-active-color;
    }

    @include xga {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__message {
    order: 3;
    height: 20px;
    font-weight: 300;
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    &--error {
      color: $primary-red;
    }
  }
}
