@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.filters-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: $secondary-background-color;
  width: 100%;
  left: 0;
  padding: 20px 16px;
  border: 1px solid $tertiary-border-color;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  z-index: 8;

  @include wxga() {
    width: 932px;
    left: 30px;
    top: 131px;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-rows: 0fr 0fr 0fr;
    grid-template-columns: 4fr 5fr;
    padding: 0;
  }

  &__container {
    padding: 15px 10px 32px;
    border-bottom: 1px solid $tertiary-border-color;

    &:last-of-type {
      border-bottom: none;
    }

    @include wxga() {
      padding: 14px 48px 0 11px;

      &:first-child,
      &:nth-child(2) {
        padding-bottom: 20px;
      }

      &:nth-child(2) {
        padding-right: 120px;
      }

      &:nth-child(4) {
        padding: 14px 150px 29px 11px;
      }

      &:nth-last-child(2) {
        grid-column: 1/3;
      }
    }
  }

  &__title {
    font-size: 1rem;
    color: $default-label-color;
    margin-bottom: 12px;
  }

  &__employment-types {
    display: flex;
    flex-wrap: wrap;
  }

  &__employment-type {
    font-weight: 300;
    font-size: 1rem;
    color: $default-label-color;
    padding: 7px 9px;
    border: 1px solid $border-default-color;
    border-radius: 4px;
    margin: 0 8px 5px 0;
    cursor: pointer;

    &--selected {
      font-weight: normal;
      color: $primary-color;
      background-color: $primary-light-grey;
    }
  }

  &__salary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 9px;

    @include wxga() {
      grid-template-columns: 169px 169px;
      grid-column-gap: 17px;
    }
  }

  &__button {
    border-radius: 6px;
    background-color: $primary-color;
    padding: 12px 53px;
    color: $secondary-text-color;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    align-self: center;
    cursor: pointer;

    @include wxga() {
      height: 47px;
      justify-self: end;
      align-self: end;
      margin: 0 16px 10px 0;

      position: absolute;
    }
  }

  .commission {
    @include wxga() {
      display: grid;
      grid-template-columns: 169px 338px;
      grid-column-gap: 17px;
      padding-bottom: 40px;
    }

    &__values {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 7px;
      padding-top: 12px;

      @include wxga() {
        padding-top: 0;
        grid-template-columns: 169px 169px;
        grid-column-gap: 17px;
      }
    }
  }
}
