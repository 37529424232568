@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-card {
  background: $primary-card-background;
  box-shadow: 0 4px 12px $border-default-color;
  border-radius: 8px;
  width: 100%;

  &__container {
    display: flex;
    padding: 14px 11px 9px 8px;
  }

  &__logo {
    width: 40px;
    margin: 4px 8px 0 0;
  }

  &__image {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;

    border: 1px solid $border-default-color;

    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      width: 100%;
      object-fit: cover;
    }

    &:empty {
      position: relative;

      background-color: $default-logo-background-color;

      &::after {
        top: 50%;
        left: 50%;
        width: 6px;
        content: '';
        height: 30px;
        position: absolute;
        transform: translate(-50%, -50%) skew(20deg);

        background-color: $primary-black;
      }
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 13px 0;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__position {
    font-weight: 500;
    padding: 0 0 4px;
    font-size: 0.875rem;
    color: $primary-title-color;
  }

  &__company,
  &__date {
    line-height: 1;
    font-size: 0.875rem;
    color: $description-color;
  }

  &__status {
    padding: 10px 0 0;
  }

  &__job-status {
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: capitalize;

    color: $status-color-active;

    &--draft {
      color: $status-color-draft;
    }

    &--paused {
      color: $status-color-paused;
    }

    &--position_filled {
      color: $status-color-filled;
    }

    &--withdrawn {
      color: $status-color-withdrawn;
    }
  }
}
