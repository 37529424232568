@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.language-tile {
  display: grid;
  grid-template-columns: 32px 1fr 72px;
  grid-column-gap: 5px;

  margin-bottom: 13px;

  &:last-child {
    margin-bottom: 0;
  }

  &__delete {
    width: 100%;

    button {
      width: 100%;
      height: 100%;
      cursor: pointer;
      font-size: 0.75rem;
      border-radius: 5px;
      transition: 0.2s all;

      color: $secondary-label-color;
      border: 1px solid $border-default-color;
      background-color: $secondary-background-color;

      &:hover {
        background-color: $border-default-color;
      }
    }
  }

  &__level {
    text-align: center;
    width: max-content;
    position: relative;
    border-radius: 5px;
    line-height: 1.375rem;

    color: $primary-color;
    background-color: $primary-light-grey;

    border: 1px solid $border-tertiary-color;

    @include xga {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__select {
    z-index: 2;
    height: 100%;
    border: none;
    cursor: pointer;
    appearance: none;
    border-radius: 5px;
    position: relative;
    font-weight: normal;
    font-size: 1.125rem;
    padding: 0 30px 0 16px;
    background-color: rgba(0, 0, 0, 0);

    color: $primary-color;
  }

  &__icon {
    top: 0;
    z-index: 1;
    right: 14px;
    height: 38px;
    display: flex;
    font-size: 0.75rem;
    position: absolute;
    align-items: center;
    transform: rotate(270deg);

    color: $primary-color;
  }
}
