@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.email-confirmation {
  width: 342px;
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  align-items: center;
  flex-direction: column;

  @include xga {
    width: 712px;
    min-height: unset;
  }

  &__header {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    padding: 128px 0 8px;
    line-height: 2.0625rem;

    color: $primary-title-color;

    @include xga() {
      font-size: 1.75rem;
      padding: 140px 0 22px;
      line-height: 2.375rem;
    }
  }

  &__description {
    font-weight: 300;
    text-align: center;
    line-height: 1.375rem;

    color: $primary-black;

    @include xga {
      font-size: 1.5rem;
      line-height: 2.0625rem;
    }
  }

  &__form {
    width: 100%;
  }

  &__field {
    width: 100%;
    position: relative;

    @include xga {
      display: flex;
      justify-content: center;
    }
  }

  &__input {
    width: 100%;
    border: none;
    font-weight: 300;
    border-radius: 8px;
    font-size: 1.125rem;
    padding: 19px 14px;
    line-height: 1.5625rem;

    color: $primary-text-color;
    background-color: $primary-btn-text-color;

    @include xga() {
      width: 432px;
      height: 76px;
      display: flex;
      padding: 0 18px;
      align-items: center;
      font-size: 1.125rem;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    font-weight: 300;
    margin: 202px 0 0;
    align-items: center;
    line-height: 1.375rem;
    flex-direction: column;

    @include xga() {
      bottom: unset;
      margin: 366px 0 0;
      position: relative;
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }

  &__alternative {
    margin: 6px 0 0;
    font-weight: 300;
    text-align: center;
    line-height: 1.375rem;

    a {
      margin: 0 0 0 2px;
      color: $primary-color;
      text-decoration: underline;
    }

    @include xga {
      margin: 20px 0 0;
    }
  }
}
