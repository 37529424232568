@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.statistics {
  @include xga {
    margin: 0 0 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    background-color: $secondary-background-color;
    gap: 0 20px;
    padding: 13px 16px 13px 19px;
    color: $default-label-color;

    @include xga() {
      display: none;
    }
  }

  &__icon {
    font-size: 0.625rem;
    transform: rotate(270deg);

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.375rem;
  }

  &__action {
    width: fit-content;
    margin-left: auto;
    font-weight: 500;
    font-size: 1rem;
    color: $primary-color;
    cursor: pointer;
  }

  &__expand-button {
    display: none;

    @include xga() {
      display: flex;
      align-items: center;
      gap: 0 3px;
      width: fit-content;
      margin-left: auto;
      font-weight: 300;
      font-size: 1rem;
      color: $primary-description-color;
      cursor: pointer;

      i {
        font-size: 1.375rem;
      }
    }
  }

  &__content {
    display: flex;
    gap: 0 10px;
    overflow-x: scroll;
    background-color: $secondary-background-color;
    padding: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include xga() {
      background-color: $default-background-color;
      padding: 16px 0;
    }
  }
}
