@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-numeric-field {
  display: flex;
  flex-direction: column;
  font-family: 'Halyard Display', sans-serif;

  &__label {
    order: 1;
    font-weight: 300;
    margin-left: 4px;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    & > sup {
      color: red;
    }
  }

  &--new-job > #{&}__label {
    @include xga() {
      font-size: 1rem;
      color: $default-label-color;
      font-weight: normal;
    }
  }

  &__input {
    order: 2;

    input {
      width: 100%;
      font-size: 1rem;
      font-weight: 300;
      padding: 8px 16px;
      border-radius: 4px;
      box-sizing: border-box;
      font-family: 'Halyard Display', sans-serif;

      border: 1px solid $border-default-color;

      :focus {
        border-color: $border-active-color;
        color: $border-active-color;
      }

      @include xga {
        padding: 7px 16px;
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    &--with-icon {
      position: relative;

      input {
        padding: 8px 42px 8px 16px;

        @include xga {
          padding: 7px 42px 7px 16px;
        }
      }
    }
  }

  &__icon {
    top: 0;
    right: 12px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;

    i {
      font-size: 1rem;

      color: $primary-color;
    }
  }

  &__message {
    order: 3;
    height: 20px;
    font-weight: 300;
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    &--error {
      color: $primary-red;
    }
  }
}
