@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.save-search {
  position: relative;

  @include wxga() {
    margin-bottom: 0;
    width: 137px;
  }

  @include wxgaplus() {
    width: 154px;
  }

  &__button {
    position: relative;
    width: 100%;
    padding: 12px 0;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    border-radius: 21px;
    color: $primary-color;
    background-color: $secondary-background-color;
    border: 1px solid $primary-color;

    i {
      margin-right: 9px;
    }

    & ~ .save-modal {
      z-index: 1;
    }
  }
}

.save-modal {
  position: absolute;
  top: 0;
  z-index: 7;
  padding: 62px 12px 17px;
  background-color: $secondary-background-color;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 2px 2px;
  width: 100%;

  @include wxga() {
    padding-top: 13px;
    top: 63px;
    width: 325px;
    border-radius: 2px;
    right: 0;
  }

  &__header {
    font-size: 1.125rem;
    color: $default-label-color;
    margin-bottom: 11px;
  }

  &__description {
    font-weight: 300;
    font-size: 1rem;
    color: $tertiary-description-color;
    margin: 10px 0 35px;
  }

  &__button {
    background: $primary-color;
    border-radius: 2px;
    border: none;
    width: 100%;
    padding: 4px 0;
    font-size: 1.125rem;
    color: $secondary-text-color;
  }
}
