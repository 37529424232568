@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.refund-details-form {
  @include xga() {
    width: 600px;
  }

  &__header {
    margin-bottom: 21px;

    @include xga() {
      margin-bottom: 44px;
    }
  }

  &__prefill {
    font-size: 1rem;
    cursor: pointer;
    color: $primary-color;

    &:hover {
      text-decoration: underline;
    }
  }

  &__title {
    color: $default-label-color;
    font-size: 1.125rem;

    @include xga() {
      font-size: 1.5rem;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 1rem;

    @include xga() {
      font-size: 1.125rem;
    }
  }

  &__content {
    @include xga() {
      max-width: 400px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 0 13px;
    margin-top: 20px;

    @include xga() {
      justify-content: flex-end;
    }
  }

  &__button {
    cursor: pointer;
    padding: 15px 35px;
    border: none;
    border-radius: 6px;
    background-color: $primary-color;
    color: $primary-btn-text-color;
    font-weight: 500;
    font-size: 1.125rem;

    &--secondary {
      color: $primary-color;
      background-color: $default-tile-color;
    }
  }
}
