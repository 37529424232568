@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.login {
  width: 332px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;

  @include xga {
    width: 432px;
  }

  &--with-description {
    .login__header {
      padding: 92px 0 8px;

      @include xga {
        padding: 74px 0 18px;
      }
    }
  }

  &__header {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 92px 0 63px;
    line-height: 2.0625rem;

    color: $primary-title-color;

    @include xga() {
      font-size: 1.75rem;
      padding: 40px 0 58px;
      line-height: 2.375rem;
    }

    @include wxgaplus() {
      padding: 74px 0 86px;
    }
  }

  &__description {
    font-weight: 300;
    padding: 0 0 68px;
    text-align: center;
    line-height: 1.375rem;

    color: $primary-description-color;

    @include xga {
      padding: 0 0 118px;
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }

  &__linkedin {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .linkedin {
      width: 100%;

      &__button {
        width: 100%;
        padding: 14px;
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        align-items: center;
        justify-content: space-between;

        border: 1px solid $linkedin-color;
        background-color: $primary-btn-text-color;

        @include xga {
          width: 432px;
          margin: 0 auto;
          padding: 20px 18px;
        }

        img {
          height: 34px;
        }
      }

      &__title {
        font-size: 1.125rem;
        line-height: 1.5625rem;

        color: $linkedin-color;
      }

      &__description {
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 1.25rem;

        color: $primary-description-color;

        @include xga() {
          font-size: 1.125rem;
          line-height: 1.5625rem;
        }
      }
    }
  }

  &__divider {
    width: 332px;
    padding: 46px 0;
    overflow: hidden;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.25rem;

    color: $primary-description-color;

    @include xga() {
      width: 432px;
      padding: 44px 0;
      font-weight: 300;
      line-height: 1.5rem;
      font-size: 1.125rem;
    }

    &:before,
    &:after {
      background-color: $primary-description-color;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  &__form {
    width: 100%;
  }

  &__field {
    width: 100%;
    margin: 0 0 24px;
    position: relative;

    &--with-icon {
      button {
        top: 0;
        padding: 0;
        right: 16px;
        height: 100%;
        border: none;
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0);

        i {
          cursor: pointer;
          font-size: 1.625rem;
          transition: 0.2s color;
          color: $secondary-inactive-color;

          &:hover {
            color: $primary-lila;
          }
        }
      }

      .login__input {
        padding: 19px 56px 19px 14px;

        @include xga {
          padding: 0 60px 0 18px;
        }
      }
    }

    &--with-icon-active {
      button i {
        color: $primary-color;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &--last {
      margin: 0;
    }
  }

  &__input {
    width: 100%;
    border: none;
    font-weight: 300;
    border-radius: 8px;
    padding: 19px 14px;
    font-size: 1.125rem;
    line-height: 1.5625rem;

    color: $primary-text-color;
    background-color: $primary-btn-text-color;

    @include xga() {
      height: 76px;
      display: flex;
      padding: 0 18px;
      font-size: 1.125rem;
      align-items: center;
    }
  }

  &__reset {
    width: 100%;
    font-weight: 300;
    text-align: right;
    font-size: 1.125rem;
    padding: 6px 0 104px;
    line-height: 1.5625rem;

    a {
      color: $primary-blue;
    }

    @include xga() {
      padding: 6px 0 41px;
    }

    @include wxgaplus() {
      padding: 6px 0 104px;
    }
  }

  &__actions {
    display: flex;
    font-weight: 300;
    align-items: center;
    line-height: 1.375rem;
    flex-direction: column;

    @include xga() {
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }

  &__alternative {
    margin: 6px 0 0;
    font-weight: 300;
    text-align: center;
    line-height: 1.375rem;

    a {
      margin: 0 0 0 2px;
      color: $primary-color;
      text-decoration: underline;
    }

    @include xga {
      margin: 20px 0 0;
    }
  }
}
