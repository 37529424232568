@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-process-column-header {
  width: 150px;
  font-size: 0.75rem;
  color: $secondary-title-color;
  padding: 0 0 14px;

  @include wxga() {
    margin: 0 3px 0;
    padding: 0 0 15px;
  }
}
