@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.contact-details {
  background-color: $primary-card-background;

  @include xga {
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 6px 18px rgba(62, 56, 106, 0.06);
  }

  &__header {
    display: grid;
    grid: 1fr / 1fr 0fr;
    align-items: center;

    @include wxga() {
      padding-left: 22px;
    }
  }

  &__menu {
    position: relative;
    padding-right: 16px;

    i {
      font-size: 2rem;
      cursor: pointer;
    }
  }

  &__dropdown {
    display: none;

    &--is-open {
      display: block;
      position: absolute;
      right: 21px;
      width: 216px;
      margin-top: 15px;
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.14);
      border-radius: 6px;
      background-color: $primary-card-background;
    }
  }

  &__action {
    display: grid;
    grid: 1fr / 0fr 1fr;
    gap: 0 10px;
    padding: 16px 13px 16px 16px;
    align-items: center;
    color: $secondary-description-color;
    font-weight: 300;
    font-size: 1.125rem;

    i {
      cursor: initial;
      font-size: 1.125rem;
    }
  }

  &__stats {
    display: flex;
    gap: 0 53px;
    padding: 20px 16px;

    @include wxga() {
      padding-left: 40px;
      border-top: 1px solid $border-default-color;
    }
  }

  &__row {
    display: grid;
    grid: 1fr / 1fr 1fr;
    padding: 20px 16px 28px;
    border-top: 1px solid $border-default-color;

    @include wxga() {
      padding-left: 40px;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 0.875rem;
    color: $primary-text-color;
    margin-bottom: 8px;

    &--secondary {
      font-weight: normal;
      color: $label-light;
      margin-bottom: 6px;
    }
  }

  &__value,
  &__list-element {
    font-size: 0.875rem;
    color: $description-color;
  }

  &__list {
    padding: 8px;
  }

  &__list-element {
    list-style: square inside;
  }
}
