@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-talent {
  padding-bottom: 31px;

  background-color: $secondary-background-color;

  @include xga() {
    display: grid;
    background-color: unset;
    grid-template-columns: auto 1fr;
  }

  &__progress {
    padding: 24px 8px 16px;

    @include xga {
      padding: 0;
    }
  }

  &__steps {
    @include xga {
      border-radius: 6px;
      padding: 30px 16px 12px 30px;

      background-color: $secondary-background-color;
    }
  }

  .new-talent-job {
    padding: 0 12px;

    border-bottom: 1px solid $border-default-color;
    @include xga {
      padding: 0;
      margin: 0 0 16px;
    }

    &__head {
      display: flex;
      justify-content: flex-start;
    }

    &__status {
      display: initial;
      padding: 2px 11px;
      border-radius: 4px;
      height: fit-content;
      margin: 14px 0 0 30px;
      text-transform: capitalize;

      color: $secondary-text-color;
      background-color: $status-open-color;
    }

    &__title {
      margin: 0;
      text-align: left;
      margin-bottom: 20px;
    }

    &__basic-info {
      display: flex;
      justify-content: flex-start;
    }

    &__position {
      font-weight: 500;
      font-size: 2.125rem;
      color: $default-label-color;

      @include xga() {
        font-size: 1.75rem;
      }
    }

    &__location,
    &__company {
      color: $primary-title-color;
      font-size: 1rem;
    }

    &__location {
      padding-right: 9px;
      border-right: 1px solid $default-label-color;
    }

    &__company {
      margin-left: 9px;
    }
  }

  .new-talent-step {
    @include xga {
      padding: 0 12px;
    }

    &__header {
      padding: 16px;

      @include xga {
        padding: 0;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.5rem;

      color: $primary-title-color;

      @include xga {
        font-size: 1.75rem;
        line-height: 2.375rem;
      }
    }

    &__description {
      margin: 4px 0 0;
      line-height: 1.375rem;

      color: $secondary-description-color;

      @include xga {
        padding: 6px 0 14px;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      padding: 32px 16px 0;
      text-align: right;

      @include xga {
        padding: 32px 0 0;
      }

      span {
        align-self: end;
      }

      a {
        color: $primary-color;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__button {
      border: none;
      cursor: pointer;
      font-weight: 500;
      margin: 0 0 0 12px;
      border-radius: 6px;
      padding: 15px 35px;
      line-height: 1.5rem;
      font-size: 1.125rem;

      color: $primary-btn-text-color;
      background-color: $primary-color;

      &--secondary {
        color: $primary-color;
        background-color: $secondary-btn-color;
      }
    }
  }
}
