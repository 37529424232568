@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.create-profile-basic {
  @include xga {
    display: grid;
    column-gap: 64px;
    grid-template-columns: 1fr 1fr;
  }
}
