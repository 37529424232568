@import 'src/ui/scss/responsiveness';
@import './preferences.scss';
@import './About.scss';
@import './App.scss';
@import './buttons.scss';
@import './Colors.scss';
@import './Footer.scss';
@import './Form.scss';
@import './Forms.scss';
@import './Header.scss';
@import './image.scss';
@import './Landing.scss';
@import './notification.scss';
@import './PreviousCustomers.scss';
@import './randomColors.scss';
@import './rating.scss';
@import './Search.scss';
@import './spinner.scss';
@import './truncated.scss';
@import './variables.scss';
@import './fonts.scss';

@import './Dashboard.scss';

* {
  font-family: 'Halyard Display', sans-serif;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.mobile-only {
  @include xga {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include xga {
    display: initial;
  }
}

button {
  font-family: 'Halyard Display', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.67em;
}

@include max-mobile() {
  #axeptio_main_button {
    transform: translate(-45px, 37px) !important;
    &:hover {
      transform: translate(-20px, 15px) scale(1.1) !important;
    }
  }
}
