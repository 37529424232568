@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.payment-method-tile {
  display: grid;
  grid: repeat(3, 0fr) / 0fr 1fr 1fr;
  align-items: center;
  padding: 10px 14px;
  gap: 10px 11px;
  border-radius: 6px;
  background-color: $primary-lighter-purple;
  cursor: pointer;

  @include wxga() {
    grid: 1fr / 0fr 3fr 2fr 1fr 1fr;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__indicator {
    grid-row: 1/2;
    grid-column: 1/2;
    height: 15px;
    width: 15px;
    border: 1px solid $primary-color;
    border-radius: 50%;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

  &--default {
    background: $primary-light-grey;

    & > .payment-method-tile__indicator {
      position: relative;
      border: none;
      background-color: $secondary-light-grey;

      &::after {
        content: '';
        position: absolute;
        left: 3px;
        top: 3px;
        background-color: $primary-color;
        height: 9px;
        width: 9px;
        border-radius: 50%;
      }
    }
  }

  &__title {
    grid-row: 1/2;
    grid-column: 2/3;
    font-size: 0.875rem;
    color: $default-label-color;

    @include wxga() {
    }
  }

  &__actions {
    grid-row: 1/2;
    grid-column: 3/4;
    justify-self: end;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 5/6;
    }
  }

  &__button {
    background-color: $transparent-color;
    border: none;
    font-size: 1rem;
    cursor: pointer;

    &--edit {
      color: $primary-color;
    }

    &--delete {
      color: $primary-text-red;
    }
  }

  &__field {
    grid-column: 1/4;
    display: flex;
    justify-content: space-between;

    @include wxga() {
      grid-row: 1/2;
      grid-column: auto;
    }
  }

  &__label {
    font-weight: 300;
    font-size: 0.875rem;
    color: $secondary-label-color;

    @include wxga() {
      display: none;
    }
  }

  &__value {
    font-size: 0.875rem;
    color: $default-label-color;
  }
}
