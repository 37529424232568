@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.notifications-page {
  padding: 0 13px;

  @include xga() {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
    border-radius: 6px;
  }
}
