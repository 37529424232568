@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.base-loader {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite; /* Safari */

  border: 16px solid $secondary-text-color;
  border-top: 16px solid #00000000;

  &--m {
    width: 16px;
    height: 16px;
    border-width: 2px;
    animation-duration: 1s;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
