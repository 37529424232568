@import './Colors.scss';

.title-text {
  color: #2a9ab7;
  font-size: 2.5em;
  font-weight: bold;
  padding: 80px 0px 20px;
  line-height: normal;
}

.about-content-block {
  padding: 0px 10px;
  background-color: #f5f6f8;
}

.subtitle-info-text {
  display: block;
  padding: 30px 15px;
  color: black;
}

@media only screen and (min-width: 768px) {
  .subtitle-info-text {
    width: 60%;
  }
}

.info-title {
  color: #2a9ab7;
  font-weight: bold;
  margin: 20px 0px;
  line-height: normal;
}

.delete-profile-window {
  background-color: $btn-profile-form-cancel-color;
}
