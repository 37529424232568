@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.my-profile {
  background-color: $secondary-background-color;

  @include xga {
    display: grid;
    column-gap: 24px;
    padding: 52px 26px;
    grid-template-rows: masonry;
    grid-template-columns: 600px 1fr;

    grid-template-areas:
      'image details'
      'image social'
      'company social';
  }

  &__image {
    display: flex;
    min-height: 260px;
    height: max-content;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border-default-color;

    @include xga {
      height: 420px;
      grid-area: image;
      overflow: hidden;
      border-radius: 16px;

      &--default {
        border: 1px solid $border-default-color;
      }
    }

    img {
      width: 100%;
    }

    i {
      opacity: 0.26;
      font-size: 5.125rem;

      color: $primary-link-color;
    }
  }

  &__section {
    @include xga {
      display: inline-block;

      &--details {
        grid-area: details;
      }

      &--company {
        grid-area: company;
      }

      &--social {
        grid-area: social;
      }
    }
  }

  &__head {
    position: relative;
    padding: 16px 16px 20px;

    border-bottom: 1px solid $border-default-color;

    @include xga {
      padding: 0 0 10px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 2.125rem;
    line-height: 3.25rem;

    color: $default-label-color;

    @include xga {
      font-size: 1.75rem;
      line-height: 2.125rem;
    }
  }

  &__base {
    padding: 0 0 32px;
    line-height: 1.125rem;

    color: $description-color;

    span {
      color: $default-label-color;
    }

    @include xga {
      padding: 0 0 18px;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  &__about {
    line-height: 1.375rem;

    color: $description-color;

    @include xga {
      padding: 16px 0 0;
    }
  }

  &__button {
    top: 24px;
    right: 16px;
    position: absolute;

    @include xga {
      right: 0;
      top: 20px;
    }

    a {
      width: 42px;
      height: 42px;
      border: none;
      display: flex;
      cursor: pointer;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      background-color: $secondary-btn-color;

      i {
        font-size: 1.125rem;

        color: $primary-color;
      }
    }
  }

  &__title {
    display: flex;
    font-weight: 500;
    align-items: center;
    font-size: 1.375rem;
    line-height: 1.75rem;
    padding: 20px 16px 6px;

    color: $default-label-color;

    &--with-icon {
      height: 40px;
    }

    &--desktop-only {
      display: none;

      @include xga {
        display: flex;
      }
    }

    @include xga {
      font-size: 1rem;
      padding: 22px 0 0;
      line-height: 1.375rem;
    }
  }

  &__rows {
    padding: 0 8px;

    @include xga {
      padding: 12px 0 0;
    }
  }

  &__row.row {
    $row: 'row';

    display: flex;
    padding: 16px 8px;
    line-height: 1.125rem;
    justify-content: space-between;
    border-bottom: 1px solid $border-default-color;

    @include xga {
      padding: 10px 0;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }

    .#{$row}__label {
      color: $description-color;

      @include xga {
        padding: 0 24px 0 0;
      }
    }

    .#{$row}__value {
      max-width: 162px;
      text-align: right;
      word-wrap: break-word;

      color: $default-label-color;

      @include xga {
        max-width: 256px;
      }
    }
  }

  &__logo {
    width: 46px;
    height: 40px;
    display: flex;
    overflow: hidden;
    margin: 0 6px 0 0;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-default-color;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__text {
    padding: 0 16px;
    line-height: 1.375rem;

    color: $description-color;

    @include xga {
      padding: 0;
    }
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 16px 20px;

    @include xga {
      padding: 14px 0 20px;
    }
  }

  &__social {
    width: max-content;
    margin: 0 10px 6px 0;

    a {
      border: none;
      display: flex;
      cursor: pointer;
      font-weight: 500;
      border-radius: 6px;
      padding: 12px 14px;
      width: max-content;
      align-items: center;
      line-height: 1.125rem;
      justify-content: center;

      color: $primary-color;
      background-color: $secondary-btn-color;
    }

    i {
      display: none;
      margin: 0 8px 0 0;
    }

    &--linkedin {
      a {
        color: $button-color-linkedin;
        background-color: $button-background-linkedin;

        i {
          display: block;

          color: $button-color-linkedin;

          &::before {
            content: '\e92f';
          }
        }
      }
    }

    &--twitter {
      a {
        color: $button-color-twitter;
        background-color: $button-background-twitter;

        i {
          display: block;
          font-size: 0.875rem;

          color: $button-color-twitter;

          &::before {
            content: '\e92d';
          }
        }
      }
    }

    &--xing {
      a {
        color: $button-color-xing;
        background-color: $button-background-xing;

        i {
          display: block;

          color: $button-color-xing;

          &::before {
            content: '\e92e';
          }
        }
      }
    }
  }
}
