@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.payment-method-form {
  @include xga() {
    width: 600px;
  }

  &__header {
    color: $default-label-color;
    font-size: 1.125rem;
    margin-bottom: 21px;

    @include xga() {
      font-size: 1.5rem;
      margin-bottom: 44px;
    }
  }

  &__prefill {
    font-size: 1rem;
    cursor: pointer;
    color: $primary-color;

    &:hover {
      text-decoration: underline;
    }
  }

  &__types {
    display: flex;
    gap: 0 20px;
  }

  &__type {
    display: flex;
    align-items: center;
    gap: 0 14px;

    input {
      cursor: pointer;
    }

    input[type='radio']:after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: $secondary-background-color;
      border: 1px solid $primary-color;
    }

    input[type='radio']:checked:after {
      background-color: $primary-color;
      border: 3px solid $primary-radio-checked-background-color;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__message {
    height: 20px;
    font-weight: 300;
    text-align: right;
    font-size: 0.875rem;
    color: $primary-red;
  }

  &__content {
    @include xga() {
      max-width: 400px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 0 13px;
    margin-top: 20px;

    @include xga() {
      justify-content: flex-end;
    }
  }

  &__button {
    cursor: pointer;
    padding: 15px 35px;
    border: none;
    border-radius: 6px;
    background-color: $primary-color;
    color: $primary-btn-text-color;
    font-weight: 500;
    font-size: 1.125rem;

    &--secondary {
      color: $primary-color;
      background-color: $default-tile-color;
    }
  }
}
