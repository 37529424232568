@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.talent-skills {
  display: flex;
  flex-direction: column;
  padding: 42px 13px 35px;
  gap: 21px 0;
  max-width: 522px;

  @include wxga() {
    padding: 35px 11px 31px;
  }
}
