@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-search {
  background-color: $secondary-background-color;
  padding: 16px;

  @include xga() {
    position: relative;
  }

  @include wxga() {
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
    border-radius: 6px;
    padding: 24px 37px 70px 30px;
  }

  &__container {
    @include xga() {
      margin-bottom: 35px;
    }
  }

  &__searchbar {
    display: flex;
    flex-direction: column;
    gap: 14px;

    & > div {
      @include wxga() {
        &:first-child {
          width: 405px;
        }

        &:nth-child(2) {
          width: 280px;
        }
      }

      @include wxgaplus() {
        &:first-child {
          width: 456px;
        }

        &:nth-child(2) {
          width: 315px;
        }
      }
    }

    @include wxga() {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    @include xga() {
      margin-bottom: 22px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.5rem;
    color: $default-label-color;
  }

  &__description {
    font-size: 1rem;
    font-weight: 300;
    color: $secondary-description-color;

    @include xga {
      font-size: 1.125rem;
    }
  }

  &__my-jobs {
    font-family: 'Halyard Display', sans-serif;
    border: none;
    background-color: $primary-card-background;
    color: $primary-color;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;

    i {
      margin-right: 10px;
    }
  }

  &__button {
    border-radius: 6px;
    background-color: $primary-color;
    padding: 12px 0;
    color: $secondary-text-color;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;

    &--save {
      border-radius: 21px;
      color: $primary-color;
      background-color: $secondary-background-color;
      border: 1px solid $primary-color;

      i {
        margin-right: 9px;
      }
    }

    @include wxga() {
      margin-bottom: 0;
      width: 137px;
    }

    @include wxgaplus() {
      width: 154px;
    }
  }

  &__results {
    margin-top: 20px;
  }
}
