@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-process {
  width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: $secondary-tile-color;
  }

  ::-webkit-scrollbar-thumb {
    background: $border-default-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }

  .react-kanban-board {
    background-color: $default-background-color;
    padding: 46px 16px;

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include wxga() {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 0 12px;
      }
    }

    @include wxga() {
      background-color: $secondary-background-color;
      padding: 39px 0 26px;
    }
  }

  .react-kanban-column {
    background-color: $default-background-color;
    border-radius: 6px;
    width: 100%;
    margin-top: 28px;

    &:first-child {
      margin-top: 0;
    }

    & > div > div > div {
      width: 100%;
    }

    @include wxga() {
      background-color: $primary-process-column-color;
      margin-top: 0;
      padding: 7px;

      &:last-child {
        background-color: $secondary-process-column-color;
        position: relative;
        height: max-content;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -13px;
          left: 42%;
          width: 0;
          border-width: 20px 14px 0;
          border-style: solid;
          border-color: $secondary-process-column-color transparent;
        }
      }
    }
  }
}
