@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.recruiter-talent {
  .talent {
    color: $primary-text-color;
    background-color: $secondary-background-color;

    @include xga {
      padding: 18px 24px;
      border-radius: 6px;
      box-shadow: 0px 6px 18px rgba(62, 56, 106, 0.06);
    }

    &__breadcrumbs {
      display: flex;
      padding: 0 0 16px;
      justify-content: flex-end;
    }

    &__next {
      gap: 0 12px;
      display: flex;
      line-height: 1;
      font-size: 1.125rem;
      align-items: center;

      color: $primary-text-green;

      i {
        font-size: 0.875rem;
      }
    }

    &__head {
      position: relative;

      @include xga {
        display: flex;
        min-height: 182px;
        padding: 0 0 0 258px;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__avatar {
      width: 100%;
      height: 314px;
      display: flex;
      font-size: 4rem;
      position: relative;
      align-items: center;
      justify-content: center;

      color: $secondary-text-color;
      background-color: $primary-color;

      @include xga {
        top: 0;
        left: 0;
        width: 242px;
        height: 182px;
        overflow: hidden;
        position: absolute;
        border-radius: 4px;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__favorite {
      right: 16px;
      width: 42px;
      height: 42px;
      bottom: 10px;
      border: none;
      display: flex;
      position: absolute;
      font-size: 1.25rem;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      background-color: #ffffffaa;

      color: $primary-text-blue;

      @include xga {
        display: none;
      }
    }

    &__basic {
      margin: 24px 16px;
      position: relative;
      padding: 0 100px 22px 0;

      border-bottom: 1px solid $border-default-color;

      @include xga {
        margin: 0;
        border: none;
        padding: 4px 150px 22px 0;
      }
    }

    &__name {
      font-weight: 500;
      padding: 0 0 4px;
      font-size: 1.75rem;
      line-height: 2.125rem;

      @include xga {
        width: 100px;
        word-break: keep-all;
      }
    }

    &__position {
      line-height: 1.125rem;
    }

    &__actions {
      right: 0;
      gap: 0 8px;
      bottom: 24px;
      display: flex;
      position: absolute;

      @include xga {
        top: 0;
        bottom: unset;
      }
    }

    &__contact {
      margin: 0 16px;

      @include xga {
        margin: 0;
        gap: 0 8px;
        display: flex;
      }
    }

    &__heading {
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 1.75rem;

      &--s {
        font-size: 1rem;
        line-height: 1.125rem;

        @include xga {
          display: none;
        }
      }

      &--comment {
        display: flex;
        flex-direction: column;
        padding: 0 0 6px;

        @include xga() {
          flex-direction: row;
        }

        span {
          font-weight: 500;
          font-size: 0.875rem;
          color: $secondary-description-color;

          @include xga() {
            font-size: inherit;
            margin-left: 3px;
          }
        }
      }
    }

    &__links {
      gap: 0 10px;
      display: flex;
      padding: 20px 0;
      margin: 0 0 24px;

      border-bottom: 1px solid $border-default-color;

      @include xga {
        margin: 0;
        padding: 0;
        gap: 0 8px;
        border: none;
      }
    }

    &__link {
      gap: 0 10px;
      display: flex;
      cursor: pointer;
      font-weight: 500;
      padding: 10px 14px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      color: $button-color-linkedin;
      background-color: $button-background-linkedin;

      &--secondary {
        color: $primary-color;
        background-color: $primary-light-blue;
      }
    }

    &__button {
      width: 42px;
      height: 42px;
      border: none;
      display: flex;
      cursor: pointer;
      font-size: 1rem;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      color: $secondary-text-color;
      background-color: $primary-color;

      &--secondary {
        color: $primary-color;
        background-color: $default-tile-color;
      }

      &--tertiary {
        color: $primary-text-red;
        background-color: $primary-light-red;
      }

      &--text {
        height: 32px;
        padding: 0 20px;
        width: max-content;
      }
    }

    &__content {
      @include xga {
        gap: 0 30px;
        display: grid;
        grid-template-columns: 614px 1fr;
      }
    }

    &__details {
      margin: 0 16px;
      padding: 24px 0 0;

      @include xga {
        margin: 0;
      }
    }

    &__rows {
      padding: 24px 0 0;
    }

    &__row {
      display: flex;
      min-height: 64px;
      padding: 16px 0 14px;
      line-height: 1.125rem;

      color: $secondary-description-color;
      border-bottom: 1px solid $border-default-color;

      @include xga {
        padding: 16px 8px 14px 0;

        &:last-child {
          border: none;
        }
      }

      &--desktop-only {
        display: none;

        @include xga {
          display: flex;
        }
      }
    }

    &__label {
      flex-shrink: 0;
    }

    &__values {
      gap: 6px;
      display: flex;
      flex-wrap: wrap;
      max-width: 420px;
      margin: 0 0 0 auto;
      justify-content: flex-end;

      color: $primary-text-color;

      &--multiline {
        display: block;
        font-size: 0.875rem;
        line-height: 1.125rem;

        color: $secondary-label-color;
      }
    }

    &__value {
      display: block;
      text-align: right;

      &--badge {
        height: 24px;
        display: flex;
        padding: 0 14px;
        font-weight: 300;
        border-radius: 2px;
        align-items: center;
        letter-spacing: -0.2px;

        color: $primary-color;
        background-color: $primary-light-grey;
      }

      &--link {
        font-size: 1rem;
        padding: 0 0 4px;
        text-decoration: underline;

        color: $primary-link-color;
      }
    }

    &__job {
      margin: 56px 16px 0;

      @include xga {
        margin: 0;
        padding: 24px 0 0;
      }
    }

    &__applications {
      padding: 22px 0 24px;
    }

    &__another {
      display: flex;
      padding: 0 0 64px;
      justify-content: flex-end;
    }

    .cv {
      margin: 0 16px;
      padding: 0 0 24px;

      border-bottom: 1px solid $border-default-color;

      &__button {
        display: flex;
        margin: 20px 0 0;
        border-radius: 6px;
        padding: 14px 28px 14px 16px;

        color: $primary-link-color;
        background-color: $primary-light-blue;
      }

      &__name {
        font-weight: 500;
        line-height: 1.375rem;
      }

      &__date {
        font-size: 0.875rem;
        line-height: 1.25rem;

        color: $unpredictable-blue;
      }

      &__icon {
        display: flex;
        margin: 0 0 0 auto;
        align-items: center;
        justify-content: center;
      }
    }

    &__comment {
      display: flex;
      flex-direction: column;
      padding: 0 16px 17px;

      @include xga() {
        padding: 0 0 17px;
      }
    }

    &__post {
      padding: 15px 35px;
      font-weight: 500;
      font-size: 1.125rem;
      border: none;
      border-radius: 6px;
      background-color: $primary-color;
      color: $primary-btn-text-color;
      align-self: flex-end;
      cursor: pointer;
    }
  }
}
