@import './Colors';

input.error {
  border-color: red;
}

.label {
  color: $default-label-color !important;
}

.primary-radio {
  -webkit-appearance: none;
  background-color: #fefefe;
  border: 3px solid #eaeaea;
  border-radius: 10px;
  width: 100%;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 3px !important;

  &:focus {
    outline-color: #fefefe !important;
  }
}

.primary-radio:checked {
  background: $primary-color;
  border: 3px solid #e1e1e1;
}

.recruiter-profile-container {
  .input {
    height: 36px !important;
    border-radius: 4px;
  }
}

.auth-window-body {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #e6e7e9;
}

.modal-background {
  opacity: 0.5;
}

.modal-card-custom {
  padding: 15px;
  max-width: 500px;
}

.auth-bg {
  border-bottom: none;
  background-color: $landing-bg-color;
}

.text-link {
  color: blue;
  border-bottom: 1px solid transparent;
}

.text-link:hover {
  color: blue;
  border-bottom: 1px solid blue;
  cursor: pointer;
}

.register-privacy-info {
  a {
    color: blue;
    border-bottom: 1px solid blue;
    &:hover {
      color: rgb(73, 73, 255);
      border-bottom: 1px solid rgb(73, 73, 255);
    }
  }
}

.linkedin-signin-btn {
  width: 270px;
  height: 50px;
  margin: 0px auto;
  background-image: url('./img/in_default.png');
  background-repeat: no-repeat;
  background-size: cover;
  &:hover {
    background-image: url('./img/in_hover.png');
  }

  &:active {
    background-image: url('./img/in_active.png');
  }
}
