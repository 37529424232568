@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.create-profile-logo {
  display: flex;

  &__file {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &__button {
    text-align: left;

    button {
      border: none;
      display: flex;
      cursor: pointer;
      padding: 10px 25px;
      border-radius: 6px;
      align-items: center;
      line-height: 1.375rem;

      color: $primary-color;
      background-color: $secondary-btn-color;

      @include xga {
        padding: 12px 16px;
        font-size: 1.125rem;
        line-height: 1.5rem;
        flex-direction: row-reverse;
      }

      i {
        padding: 0 0 0 6px;

        @include xga {
          padding: 0 8px 0 0;
        }
      }
    }
  }

  &__preview {
    opacity: 0;
    width: 42px;
    height: 42px;
    display: flex;
    overflow: hidden;
    margin: 0 0 0 10px;
    border-radius: 4px;
    transition: 0.4s all;
    align-items: center;
    justify-content: center;

    border: 1px solid $border-default-color;

    @include xga {
      width: 48px;
      height: 48px;
    }

    &--active {
      opacity: 1;
    }

    img {
      height: auto;
      object-fit: cover;
    }
  }
}
