@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.deals {
  padding: 31px 16px 80px;

  @include wxga() {
    padding: 21px 77px 10px 46px;
  }

  &__header {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include wxga() {
      font-weight: normal;
      font-size: 1.125rem;
    }
  }

  &__content {
    @include wxga() {
      padding: 0 0 200px;
    }
  }

  &__no-data {
    color: $description-color;
    font-size: 1rem;
    margin: 20px;
  }
}
