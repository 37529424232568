@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$menu-items: 4;
$menu-item-width: 138px;
$menu-item-height: 26px;
$menu-item-margin-right: 40px;

.settings {
  border-radius: 6px;

  background-color: $secondary-background-color;

  @include xga {
    min-height: 764px;
    padding: 22px 52px 14px 52px;
  }

  &__title {
    line-height: 1.5rem;
    font-size: 1.125rem;
    letter-spacing: -0.2px;

    color: $default-label-color;

    @include xga() {
      font-size: 1.5rem;
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 300;
    margin: 2px 0 30px;
    line-height: 1.25rem;
    letter-spacing: -0.2px;

    color: $secondary-description-color;

    @include xga {
      margin: 6px 0 18px;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__content {
    padding: 14px 16px;

    @include xga {
      padding: 40px 0;
    }
  }
}

.settings-navigation {
  padding: 18px 16px;
  border-bottom: 1px solid $border-default-color;

  @include xga {
    padding: 0;
    border: none;
  }

  &__select {
    z-index: 2;
    height: 30px;
    border: none;
    cursor: pointer;
    appearance: none;
    position: relative;
    font-weight: normal;
    font-size: 1.125rem;
    padding: 0 34px 0 8px;
    background-color: rgba(0, 0, 0, 0);

    color: $primary-color;
  }

  &__dropdown {
    width: max-content;
    position: relative;
    border-radius: 5px;

    background-color: $primary-light-grey;

    @include xga() {
      display: none;
    }
  }

  &__icon {
    top: 0;
    z-index: 1;
    right: 12px;
    height: 30px;
    display: flex;
    font-size: 0.75rem;
    position: absolute;
    align-items: center;
    transform: rotate(270deg);

    color: $primary-color;
  }

  &__elements {
    display: none;

    @include xga {
      display: block;
      position: relative;
      width: max-content;

      border-bottom: 1px solid $border-default-color;
    }

    &:before {
      bottom: 0;
      left: 0px;
      content: '';
      opacity: 0.1;
      display: block;
      position: absolute;
      transition: 0.2s ease-out;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      width: $menu-item-width;
      height: $menu-item-height;
      background-color: $primary-color;
    }

    @for $i from 1 through $menu-items {
      &--#{$i}:before {
        transform: translateX(calc((#{$menu-item-margin-right} * (#{$i} - 1)) + (#{$menu-item-width} * (#{$i} - 1))));
      }
    }
  }

  &__list {
    height: 100%;
    display: flex;
    position: relative;
    align-content: center;
  }

  &__item {
    text-align: center;

    width: $menu-item-width;
    height: $menu-item-height;
    margin-right: $menu-item-margin-right;
  }

  &__link {
    width: 100%;
    opacity: 0.5;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &--active {
      opacity: 1;

      color: $primary-color;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
