@import 'src/ui/scss/Colors';

.messaging-dot-menu {
  &__trigger-button {
    cursor: pointer;
    font-size: 24px;
    border: none;
    background-color: $transparent-color;
    outline: none;
    padding: 0;
  }

  .dropdown-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .dropdown-item {
    font-size: 18px;
    font-weight: 300;
    color: $primary-black;
    border-bottom: 1px solid $border-default-color;

    i {
      font-size: 14px;
      margin-right: 9px;
    }

    &:last-child {
      border: none;
    }

    &.color-red {
      color: $primary-text-red;
    }
  }
}
