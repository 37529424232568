@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.settings-commission {
  @include xga {
    width: 76%;
    margin: 56px auto 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 6px;
    padding: 15px 35px;
    margin: 18px 0 26px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    @include xga {
      padding: 10px 20px;
      font-size: 0.875rem;
      margin: 26px 0 46px;
      line-height: 1.25rem;
    }
  }

  &__footnote {
    font-weight: 300;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;

    color: $secondary-label-color;

    a {
      text-decoration: underline;
      color: $primary-link-color;
    }
  }
}
