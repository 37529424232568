@import 'src/ui/scss/Colors';

.message-field {
  padding: 14px 20px;

  &__files-list {
    width: max-content;
  }

  &__columns {
    display: flex;
    flex-direction: row;
  }

  &__attach-button {
    cursor: pointer;
    font-weight: 300;
    text-align: center;
    padding: 5px;
    margin-right: 7px;
    font-size: 14px;
    user-select: none;

    input {
      display: none;
    }

    i {
      color: $primary-color;
      font-size: 20px;
      display: block;
    }

    &--disabled {
      cursor: initial;
      opacity: 0.7;
    }
  }

  &__input-container {
    position: relative;
    flex-grow: 1;

    textarea {
      display: block;
      padding: 18px 55px 18px 14px;
      background-color: $default-background-color;
      border: none;
      outline: none;
      -webkit-appearance: none;
      border-radius: 8px;
      resize: none;
      width: 100%;
    }
  }

  &__save-button {
    cursor: pointer;
    position: absolute;
    right: 14px;
    bottom: 12px;
    border: none;
    background-color: $transparent-color;
    padding: 0;

    i {
      font-size: 20px;
      color: $primary-color;
    }

    &:disabled {
      cursor: initial;
      opacity: 0.7;
    }
  }
}
