@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.recruiter-jobs {
  .jobs {
    @include xga {
      display: grid;
      column-gap: 10px;
      grid-template-columns: max-content 1fr;
    }

    &__content {
      width: 100%;
      overflow: hidden;

      &--desktop-only {
        display: none;

        @include xga {
          display: block;
        }
      }
    }

    &__recommended {
      background-color: $secondary-background-color;
      width: fit-content;
      padding: 10px;
      border-radius: 6px;
    }

    .jobs-list {
      &--desktop-only {
        display: none;

        @include xga {
          display: block;
        }
      }

      &__content {
        width: 100%;
        overflow: hidden;
        border-radius: 6px;

        box-shadow: 0px 6px 18px rgba(62, 56, 106, 0.06);

        @include xga {
          width: 372px;
        }
      }

      &__head {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 18px 22px;

        background-color: $secondary-background-color;
      }

      &__icon {
        display: flex;
        align-items: center;
      }

      &__title {
        flex-grow: 1;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: -0.2px;
        padding: 0 20px 0 10px;

        color: $primary-text-color;
      }

      &__search a {
        height: 20px;
        display: flex;
        cursor: pointer;
        font-size: 1.25rem;
        align-items: center;

        color: $primary-color;
      }

      &__tiles {
        padding: 20px 10px;

        background-color: $default-background-color;

        a {
          display: block;
          margin: 0 0 10px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .jobs-navigation {
      display: flex;
      padding: 0 12px;
      position: relative;
      justify-content: space-between;

      background-color: $secondary-background-color;
      border-bottom: 1px solid $border-default-color;

      &::after {
        left: 0;
        bottom: 0;
        width: 64px;
        height: 2px;
        content: '';
        position: absolute;
        transition: 0.4s transform;

        transform: translateX(calc(12px + var(--index) * (64px + 30.66px)));

        background-color: $primary-color;
      }

      &__tab {
        width: 64px;
        cursor: pointer;
        padding: 0 0 8px;
        font-weight: 500;
        font-size: 0.875rem;
        text-align: center;
        line-height: 1.25rem;
        transition: 0.4s color;

        &--active {
          color: $primary-color;
        }
      }
    }
  }
}
