@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-progress {
  padding: 25px 13px 0;
  background-color: $secondary-background-color;

  @include xga() {
    padding: 0;
    margin: 0 10px 0 0;
    border-radius: 6px;
  }

  &__title {
    display: none;

    @include xga() {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primary-text-color;
      border-bottom: 1px solid $border-default-color;
      padding: 12px 20px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    position: relative;

    @include xga() {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
  }
}
