@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.badge-container {
  &__label {
    font-size: 1rem;
    color: $default-label-color;
    margin-bottom: 18px;

    @include xga() {
      font-size: 1.125rem;
    }
  }

  &__card {
    padding: 15px 11px 25px;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      padding: 15px 32px 25px;

      &:last-child,
      &:nth-last-child(2) {
        border-bottom: none;
      }
    }
  }

  &__badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__badge {
    padding: 0 16px;
    background-color: $primary-light-grey;
    color: $primary-color;
    border-radius: 2px;
    margin-right: 16px;
    margin-bottom: 9px;
  }
}
