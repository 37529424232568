@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.recruiter-rating {
  padding: 32px 16px 0;
  background-color: $primary-card-background;
  display: flex;
  flex-direction: column;

  @include wxga() {
    display: grid;
    grid: 40px repeat(4, 0fr) / 1fr 1fr;
    padding: 19px 11px 0 26px;
  }

  &__delete {
    font-family: 'Halyard Display', sans-serif;
    border: none;
    color: $btn-profile-form-cancel-color;
    background-color: $light-red;
    padding: 7px 20px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 1.125rem;
    width: fit-content;
    align-self: flex-end;
    margin-top: 24px;
    cursor: pointer;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 2/3;
      margin-left: auto;
      margin-top: 0;
    }
  }

  &__breadcrumb {
    display: none;

    @include wxga() {
      display: block;
      color: $description-color;
      font-size: 1rem;
      cursor: pointer;
      width: fit-content;
      height: fit-content;

      i {
        font-size: 0.625rem;
        margin-right: 8px;
      }
    }
  }
}
