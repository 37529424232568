@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$root: '.application-tile';

.recruiter-application-tile {
  #{$root} {
    display: flex;
    column-gap: 4px;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;

    @include xga() {
      padding: 0;
      column-gap: 72px;
    }

    &__container {
      flex-grow: 1;
      display: flex;

      @include xga {
        flex-grow: unset;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
      display: flex;
      flex-shrink: 0;
      overflow: hidden;
      margin: 0 14px 0 0;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      color: $secondary-text-color;
      background-color: $primary-color;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }

    &__info {
      flex-grow: 1;
      display: flex;

      @include xga {
        column-gap: 72px;
      }
    }

    &__talent {
      width: 102px;

      @include xga {
        width: 128px;
      }
    }

    &__date {
      display: none;

      @include xga {
        display: block;
      }
    }

    &__status {
      display: flex;
      margin: 0 0 0 auto;
      align-items: center;

      @include xga {
        margin: 0;
        display: block;
        align-items: unset;
      }

      #{$root}__row#{$root}__row--label {
        display: none;

        @include xga {
          display: flex;
        }
      }
    }

    &__row {
      display: flex;
      font-size: 0.875rem;
      line-height: 1.125rem;

      color: $secondary-description-color;

      &--label {
        padding: 0 0 2px;

        color: $primary-title-color;
      }
    }

    &__actions {
      display: flex;
      column-gap: 4px;
      flex-wrap: wrap;
      min-width: 66px;
      justify-content: flex-end;

      @include xga() {
        width: initial;
        justify-content: center;
      }
    }

    &__button {
      height: 32px;
      border: none;
      display: flex;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 6px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      align-items: center;
      letter-spacing: -0.2px;
      justify-content: center;
      font-family: 'Halyard Display', sans-serif;

      color: $secondary-text-color;
      background-color: $secondary-green;

      @include xga {
        font-weight: 500;
        padding: 4px 20px;
        margin: 0 0 0 10px;
        letter-spacing: unset;
      }

      &--secondary {
        color: $primary-color;
        background-color: $default-tile-color;
      }

      &--tertiary {
        color: $primary-text-red;
        background-color: $primary-light-red;
      }

      &--quaternary {
        color: $primary-text-blue;
        background-color: $primary-light-blue;
      }
    }
  }
}
