@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.progress-bar {
  position: relative;
  grid-row: 2/3;
  grid-column: 1/3;
  background-color: #e1e1e1;
  border-radius: 5px;
  height: 13px;
  width: 100%;

  &__completed {
    position: absolute;
    background-color: $primary-color;
    height: 100%;
    border-radius: 5px;
  }
}
