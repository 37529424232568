$primary-color: #6553df;

$default-border-radius: 6px;
$secondary-color: #2a9ab7;

$primary-disabled-color: #e0e0e0;

$primary-black: #000;
// colors for random card images
$primary-blue: #027ee0;
$primary-lila: #876fff;
$primary-green: #2a9ab7;
$primary-dark-blue: #2936a3;

$primary-red: #e76060;

$light-blue: #1da1f2;
$primary-text-color: #2b3036;
$primary-btn-text-color: #ffffff;

$default-inactive-color: #e5e5e5;

$secondary-inactive-color: #c2c8e3;

$default-background-color: #f5f6f8;
/* profile post and previous customers colors */
$btn-profile-form-color: $primary-color;
$menu-background-color: #fafafa;

$btn-profile-form-opacity-color: rgba(101, 83, 223, 0.1);
$btn-profile-form-cancel-color: #da143a;
$btn-profile-form-opacity-cancel-color: rgba(218, 20, 58, 0.1);
$default-message-color: #828282;
$default-label-color: #000000;

$dashed-border-color: #d3d3d5;
$border-tertiary-color: #dadada;
$border-secondary-color: #e0e8f5;
$border-default-color: #edecf3;
$border-active-color: #7464e2;
$landing-bg-color: #004e66;
$landing-card-bg-color: #2a768e;
$landing-skill-bg-color: #7fadbb;
$card-btn-color: #004e66;

$primary-title-color: #2e3137;
$primary-description-color: #8f92a1;
$secondary-description-color: #808080;
$tertiary-description-color: #55595e;
$linkedin-color: #0e76a8;

$secondary-btn-color: #d8d5f3;

$default-tile-color: #f0eefc;

$primary-new-message-color: #1b1a57;
$secondary-new-message-color: #4f5e7b;
$primary-card-background: #ffffff;

$messaging-active: #4ce417;
$messaging-away: #f2c94c;
$messaging-inactive: #bdbdbd;
$secondary-disabled-color: #e0ddf9;
$tertiary-disabled-color: #ccc;

$secondary-title-color: #626263;

$tertiary-btn-color: #e0ddf9;

$secondary-background-color: #ffffff;

$secondary-label-color: #999999;
$label-light: #b3b3b3;
$label-lighter: #a3a3a3;
$tertiary-label-color: #88909f;
$tertiary-inactive-color: #c4c4c4;
$secondary-text-color: #ffffff;
$tertiary-text-color: #333;

$primary-light-grey: #e7e6f6;
$secondary-light-grey: #a69deb;
$primary-lighter-purple: #fcfcfe;
$primary-dark-grey: #69667d;

$primary-tab-inactive-color: #666768;
$secondary-label-color: #979797;

$primary-link-color: #027ee0;

$divider-color: #888;
$description-color: #666;

$primary-message-color: #aaaaaa;

$button-background-linkedin: #e4f1f8;
$button-background-twitter: #e5f2fc;
$button-background-xing: #e5eeef;

$button-color-linkedin: #0076b4;
$button-color-twitter: #027ee0;
$button-color-xing: #005a5f;

$primary-text-blue: #6b9ae1;
$primary-light-blue: #f0f5fc;
$primary-text-green: #68baa6;
$primary-light-green: #e1f1ed;
$primary-text-red: #da143a;
$primary-light-red: #fbe8eb;

$primary-breadcrumb-color: #666666;

$secondary-description-color: #666666;

$secondary-tile-color: #f9f9f9;

$primary-link-color: #027ee0;

$secondary-green: #68baa6;

$secondary-light-blue: #f0f5fc;
$status-open-color: #68baa6;
$status-canceled-color: #de4848;

$primary-yellow-text-color: #caa34c;

$primary-process-column-color: #f8f9fa;

$secondary-process-column-color: #f0f8f6;
$primary-rating-color: #ecb371;

$notification-text-color: #4d4d4d;
$secondary-green: #68baa6;

$text-btn-color: #334a52;

$blue-background-color: #f8fafd;

$blue-label-color: #7dbcef;
$linkedin-background: #e5f1f7;

$secondary-select-color: #fefdff;

$primary-filer-color: #a0a2a5;
$secondary-border-color: #d9d9d9;
$tertiary-border-color: #ccc;

$primary-icon-color: #7b7b7c;
$secondary-icon-color: #666;

$separator-color: #ccc;
$transparent-color: rgba(0, 0, 0, 0);
$primary-icon-color: #7b7b7c;
$secondary-icon-color: #666;

$default-logo-background-color: #f0eefc;

$unread-message-tile-color: #f6f5fd;

$unpredictable-blue: #94c7f2;

$primary-radio-checked-background-color: #b2a9ef;
$progress-background-color: #e1e1e1;
$progress-background-complete-color: $primary-color;

$light-red: #fadfdf;

$primary-badge-color: #fff;
$primary-badge-background-color: #ecb371;
$secondary-badge-background-color: #68baa6;

$status-color-draft: #e7e5f6;
$status-color-active: #68baa6;
$status-color-filled: #f5a8b2;
$status-color-paused: #e7e5f6;
$status-color-withdrawn: #f5a8b2;

$label-background-color-draft: #e7e5f6;
$label-background-color-active: #68baa6;
$label-background-color-filled: #f5a8b2;
$label-background-color-paused: #e7e5f6;
$label-background-color-withdrawn: #f5a8b2;
