@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.notifications-dropdown {
  padding: 13px 13px 23px;
  display: flex;
  flex-direction: column;

  @include xga() {
    padding: 15px 14px 16px;
  }

  &__button {
    border: none;
    cursor: pointer;
    margin-top: 21px;
    font-weight: 400;
    align-self: center;
    font-size: 1.125rem;
    color: $primary-color;
    text-decoration-line: underline;

    background-color: $secondary-background-color;

    @include xga() {
      margin-top: 15px;
    }
  }

  &__empty {
    padding: 12px 0;
    text-align: center;
    border-bottom: 1px solid $border-default-color;
  }
}
