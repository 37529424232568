@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.dialog {
  $root: '.dialog';

  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  position: fixed;

  background-color: rgba(0, 0, 0, 0.4);

  &__content {
    top: 50%;
    left: 50%;
    width: 328px;
    margin: 0 auto;
    max-height: 100vh;
    border-radius: 6px;
    position: absolute;
    overflow-y: visible;
    padding: 28px 22px 20px;
    transform: translate(-50%, -50%);

    background-color: $secondary-background-color;

    &--has-component {
      width: max-content;
    }

    &--is-scrollable {
      overflow-y: auto;
    }

    &--has-close {
      #{$root}__title {
        padding: 0 22px 0 0;
      }
    }
  }

  &__title {
    line-height: 1.5rem;
  }

  &__body {
    padding: 8px 0;
  }

  &__message {
    font-weight: 300;
    padding: 0 0 76px;
    line-height: 1.25rem;

    color: $description-color;
  }

  &__actions {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: center;
  }

  &__button {
    height: 55px;
    border: none;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    padding: 16px 36px;
    border-radius: 6px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    align-items: center;
    justify-content: center;

    &--secondary {
      color: $primary-color;
      background-color: $default-tile-color;
    }

    &--tertiary {
      color: $primary-text-red;
      background-color: $primary-light-red;
    }

    &--quinary {
      color: $secondary-text-color;
      background-color: $primary-text-red;
    }
  }

  &__close {
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    align-items: center;
    justify-content: center;

    color: $tertiary-description-color;

    &--pixel-perfect {
      top: -9px;
    }
  }
}
