@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

$root: 'create-profile-avatar';

.create-profile-avatar {
  &__image {
    width: 328px;
    display: flex;
    height: 230px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;

    border-radius: 16px;
    border: 1px dashed $border-tertiary-color;

    @include xga {
      width: 600px;
      height: 420px;
    }

    &--default {
      i {
        opacity: 0.26;
        font-size: 5.125rem;

        color: $primary-link-color;

        @include xga {
          font-size: 13.375rem;
        }
      }

      .#{$root}__file {
        width: 100%;
        height: 100%;
      }
    }

    canvas {
      transform: scale(0.542);

      @include xga {
        transform: none;
      }
    }
  }

  &__file {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &__button {
    display: flex;
    padding: 20px 0 0;
    justify-content: center;

    button {
      border: none;
      display: flex;
      cursor: pointer;
      padding: 10px 25px;
      border-radius: 6px;
      align-items: center;
      line-height: 1.375rem;

      color: $primary-color;
      background-color: $secondary-btn-color;

      @include xga {
        padding: 12px 16px;
        font-size: 1.125rem;
        line-height: 1.5rem;
        flex-direction: row-reverse;
      }

      i {
        padding: 0 0 0 6px;
        font-size: 0.875rem;

        @include xga {
          padding: 0 8px 0 0;
        }
      }
    }
  }
}
