@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.talentpool-card {
  display: flex;
  flex-direction: column;
  padding: 6px 11px 9px;
  border: 1px solid $border-default-color;
  width: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__avatar {
    width: 66px;
    height: 66px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    img {
      height: 100%;
      width: unset;
      object-fit: cover;
    }
  }

  &__actions {
    display: flex;
    gap: 0 12px;
  }

  &__button {
    border: none;
    height: fit-content;
    background: none;
    padding: 0;
    cursor: pointer;

    &--like {
      font-size: 1.125rem;
      color: $primary-blue;
    }

    &--archive {
      font-size: 1rem;
      color: $btn-profile-form-cancel-color;
    }
  }

  &__name {
    display: block;
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.125rem;
    color: $primary-link-color;
    cursor: pointer;
    width: fit-content;
    padding-top: 4px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 6px 0;
  }

  &__row {
    display: flex;
    font-weight: 300;
    justify-content: space-between;

    color: $secondary-description-color;
  }

  &__label,
  &__value {
    font-weight: 300;
    font-size: 1rem;
  }

  &__value {
    gap: 0 4px;
    display: flex;
    align-items: center;

    i {
      color: $primary-color;
    }
  }

  &__cv {
    font-size: 1rem;
    text-decoration-line: underline;
    color: $primary-link-color;
  }

  &__links {
    gap: 0 6px;
    display: flex;
  }

  &__link {
    display: flex;
    font-family: 'Halyard Display', sans-serif;
    border: none;
    border-radius: 6px;
    padding: 6px 13px;
    font-weight: 500;
    font-size: 0.875rem;
    gap: 0 10px;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 0.938rem;
    }

    &--email {
      color: $primary-color;
      background-color: $default-tile-color;
    }

    &--linkedin {
      color: $linkedin-color;
      background-color: $linkedin-background;
    }
  }

  &__header {
    font-weight: 500;
    font-size: 1rem;
    color: $default-label-color;
    margin: 23px 0 9px;
  }
}
