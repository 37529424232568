@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.step-publish-description {
  $root: '.step-publish-description';

  display: flex;
  flex-direction: column;

  &__label {
    font-size: 14px;
    color: $primary-description-color;
  }

  &__title {
    display: none;

    @include xga() {
      display: initial;
      order: 1;
      border-bottom: 1px solid $border-default-color;
      padding: 0 13px 23px 39px;
      margin: 24px 0 22px;
    }
  }

  &__header {
    font-weight: 500;
    font-size: 28px;
    color: $default-label-color;
  }

  &__details {
    order: 2;
    display: flex;
    flex-direction: column;
    background-color: $default-background-color;
    padding: 13px 10px;
    border-radius: 4px;
    margin: 0 13px 46px;

    @include xga() {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: 0fr 0fr;
      gap: 12px 48px;
      width: fit-content;
      background-color: $secondary-background-color;
      margin: 0 0 26px;
      padding: 13px 42px 0;
    }
  }

  &__description {
    order: 3;
    padding: 22px 13px 42px;
    border-top: 1px solid $border-default-color;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      padding: 22px 87px 42px 39px;
    }

    #{$root}__label {
      margin: 12px 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }

  &__commission {
    order: 4;
    padding: 22px 13px 0;
    margin-bottom: 22px;

    @include xga() {
      order: 6;
      padding: 22px 13px 0 39px;
    }
  }

  &__commission-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $primary-color;
  }

  &__additional-info {
    order: 5;
    padding: 0 13px 43px;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      order: 7;
      border-bottom: none;
      padding: 0 13px 0 39px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 26px;
    color: $default-label-color;
    opacity: 0.8;

    ul {
      list-style: disc;
    }
  }

  &__skills {
    order: 6;

    @include xga() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      order: 4;
      padding: 0 7px 0;
    }
  }

  &__languages {
    order: 7;
    padding: 22px 12px 86px;

    @include xga() {
      order: 5;
      padding: 7px 12px 42px 39px;
      border-bottom: 1px solid $border-default-color;
    }
  }
}

.step-publish-error {
  padding: 10px;
  margin-bottom: 20px;
}

.step-publish-error__alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  a {
    cursor: pointer;
    color: $primary-link-color;
    &:hover {
      color: darken($primary-link-color, 15);
    }
  }
}

.languages {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px 16px;
  }

  &__label {
    font-size: 18px;
    line-height: 24px;
    color: $default-label-color;
    margin-bottom: 18px;
  }

  &__language {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__name {
    padding: 4px 8px;
    background-color: $primary-light-grey;
    color: $primary-color;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &__level {
    font-size: 16px;
    line-height: 22px;
    color: $secondary-text-color;
    background-color: $primary-color;
    padding: 5px 11px;
    border-radius: 2px;
  }
}

.details {
  &__label {
    font-size: 14px;
    line-height: 26px;
    color: $secondary-description-color;

    @include xga() {
      margin-right: 5px;
    }
  }

  &__value {
    font-size: 16px;
    line-height: 26px;
    color: $primary-color;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    @include xga() {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }
}
