@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.hr-job-statistics {
  .statistics {
    row-gap: 8px;
    display: grid;
    column-gap: 8px;
    position: relative;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    @include xga {
      display: flex;
      column-gap: 56px;
    }

    &__tile {
      padding: 16px 0;
      text-align: center;
      border-radius: 14px;
      line-height: 1.5rem;

      color: $description-color;
      background-color: $secondary-tile-color;

      @include xga {
        padding: 0;
        display: flex;
        max-width: 78px;
        text-align: left;
        line-height: 1.25rem;
        justify-content: flex-end;
        flex-direction: column-reverse;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    &__value {
      font-weight: 500;

      color: $primary-black;

      @include xga {
        padding: 0 0 6px;
        font-size: 1.125rem;

        color: $primary-blue;
      }
    }

    &__status {
      top: 50%;
      left: 50%;
      padding: 2px 12px;
      position: absolute;
      border-radius: 4px;
      text-transform: uppercase;
      transform: translate(-50%, -50%);

      color: $primary-btn-text-color;
      background-color: $secondary-green;

      &--draft {
        background-color: $label-background-color-draft;
      }

      &--paused {
        background-color: $label-background-color-paused;
      }

      &--position_filled {
        background-color: $label-background-color-filled;
      }

      &--withdrawn {
        background-color: $label-background-color-withdrawn;
      }

      @include xga {
        display: none;
      }
    }
  }
}
