@import 'src/ui/scss/Colors';

.messages-panel {
  background-color: $secondary-background-color;
  border-radius: 6px;
  box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
  display: flex;
  flex-direction: column;
  height: 100%;

  &__empty {
    margin: auto;
  }

  &__list {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 20px 22px;
  }

  &__system-info {
    text-align: center;
    width: 100%;
    margin-top: 36px;
    color: $primary-text-red;
  }
}
