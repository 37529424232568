@import 'src/ui/scss/Colors';

.messaging-threads-list {
  height: 100%;
  overflow-y: scroll;
  padding: 9px 9px 80px;

  &__empty {
    text-align: center;
  }
}
