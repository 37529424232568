@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.feed-card {
  box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);

  @include xga() {
    border-radius: 6px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    padding: 29px 11px;

    @include xga() {
      padding: 15px 11px;
      max-height: 276px;
      overflow-y: scroll;
      height: 100%;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 1rem;
    color: $primary-description-color;
  }

  &__wallet-title {
    display: none;
  }

  &__action {
    display: none;
  }

  &--wallet {
    .feed-card__wallet-title {
      display: block;
      font-size: 1rem;
      color: $primary-color;
      padding: 9px 10px 15px;
    }
  }

  &--jobs {
    .feed-card__action {
      display: flex;
      font-weight: 500;
      font-size: 1rem;
      color: $primary-link-color;
      cursor: pointer;
    }
  }
}
