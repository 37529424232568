@import 'src/ui/scss/Colors';

.messaging-file-message {
  border: 1px dashed $primary-description-color;
  padding: 17px 23px;
  border-radius: 100px;
  font-size: 14px;
  color: $primary-black;
  display: grid;
  grid: auto / auto 1fr auto auto auto;
  align-items: center;

  & > i {
    margin-right: 9px;
  }

  i {
    vertical-align: middle;
  }

  a {
    font-size: 15px;
    margin-left: 20px;
    color: $primary-color;
  }

  &__size {
    color: $tertiary-description-color;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
  }
}
