@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.wallet-tabs {
  $tab-margin-x: 20px;
  $tab-padding-x: 20px;
  $tab-max-width: 94px;

  &__navigation {
    display: flex;
    overflow: hidden;
    position: relative;
    margin: 0 16px;
    gap: 0 8px;

    @include xga {
      border-bottom: 1px solid $border-default-color;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;
    background-color: $border-tertiary-color;
    color: $secondary-text-color;
    padding: 13px 0;
    border-radius: 9px;
    width: 62px;
    height: 46px;
    text-align: center;
    align-items: center;

    span {
      display: none;
    }

    i {
      font-size: 1.188rem;
    }

    @include xga {
      display: initial;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: center;
      position: relative;
      line-height: 1.25rem;
      transition: 0.4s color;
      margin: 0 $tab-margin-x;
      padding: 0 $tab-padding-x 14px;
      color: $description-color;
      background-color: initial;
      width: initial;
      height: initial;

      span {
        display: initial;
      }

      i {
        display: none;
      }

      &::after {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        transition: 0.2s transform;
        transform: translateY(2px);

        background-color: $primary-color;
      }

      &:hover {
        color: $primary-color;
      }
    }

    &--active {
      background-color: $primary-text-blue;
      padding: 14px 1px 4px;
      justify-content: space-between;
      height: 80px;

      span {
        display: initial;
        font-size: 0.75rem;
        word-break: normal;
      }

      @include xga() {
        color: $primary-color;
        background-color: initial;
        height: initial;
        padding: 0 $tab-padding-x 14px;

        span {
          font-size: 0.875rem;
          word-break: keep-all;
        }

        &::after {
          transform: translateY(0);
        }
      }
    }
  }
}
