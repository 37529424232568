$media-mobile-min: 375px;
$media-largemobile-min: 600px;
$media-mobile-max: 767px;
$media-tablet-min: 768px;
$media-xga-min: 1024px;
$media-wxga-min: 1280px;
$media-hd-min: 1366px;
$media-wxgaplus-min: 1440px;
$media-fullhd-min: 1680px;

@mixin mobile() {
  @media only screen and (min-width: $media-mobile-min) {
    @content;
  }
}

@mixin max-mobile() {
  @media only screen and (max-width: $media-mobile-max) {
    @content;
  }
}

@mixin largemobile() {
  @media only screen and (min-width: $media-largemobile-min) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (min-width: $media-tablet-min) {
    @content;
  }
}

@mixin xga() {
  @media only screen and (min-width: $media-xga-min) {
    @content;
  }
}

@mixin wxga() {
  @media only screen and (min-width: $media-wxga-min) {
    @content;
  }
}

@mixin hd() {
  @media only screen and (min-width: $media-hd-min) {
    @content;
  }
}

@mixin wxgaplus() {
  @media only screen and (min-width: $media-wxgaplus-min) {
    @content;
  }
}

@mixin fullhd() {
  @media only screen and (min-width: $media-fullhd-min) {
    @content;
  }
}
