@import 'src/ui/scss/Colors';

.messaging-text-message {
  $root: &;
  $parent-message: '.messaging-message';
  padding: 8px 10px;
  white-space: pre-line;

  @at-root {
    #{$parent-message}--outgoing {
      #{$root} {
        background-color: $primary-color;
        border-radius: 8px 8px 0 8px;
        color: $secondary-text-color;
      }
    }
  }

  @at-root {
    #{$parent-message}--incoming {
      #{$root} {
        background-color: $default-background-color;
        border-radius: 8px 8px 8px 0;
      }
    }
  }
}
