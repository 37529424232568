@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$statColors: $primary-green, $secondary-green, $primary-dark-blue, $primary-blue, $primary-lila;

.statistics-card {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 2px;
  color: $secondary-text-color;
  width: 124px;
  height: 124px;
  flex-shrink: 0;
  border-radius: 16px;

  @for $i from 2 through 6 {
    &:nth-child(#{$i}) {
      background-color: nth($statColors, $i - 1);
    }
  }

  @include xga() {
    width: 190px;
    height: 130px;
    padding: 18px 18px 18px 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 0.875rem;

    @include xga() {
      font-weight: normal;
      font-size: 1.125rem;
      margin-left: auto;
    }
  }

  &__value {
    font-weight: 500;
    font-size: 1.25rem;

    @include xga() {
      font-size: 2.5rem;
      line-height: 45px;
    }
  }

  &__description {
    font-weight: 200;
    font-size: 1rem;

    @include xga() {
      font-size: 1.125rem;
    }
  }
}
