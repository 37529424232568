@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.messages-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid $separator-color;
  padding: 13px 20px 18px;

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 10px;

    margin-bottom: -15px;
    & > * {
      margin-bottom: 15px;
    }

    @include tablet {
      margin-right: 6px;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $primary-black;
  }

  h3 {
    color: $tertiary-description-color;
    font-size: 16px;
    line-height: 18px;
  }

  select {
    margin-left: auto;
  }

  .messaging-dot-menu {
    margin-left: auto;
    align-self: self-start;

    @include tablet {
      align-self: center;
    }
  }

  .dropdown-trigger {
    display: flex;
  }

  &__action-button {
    border: none;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    padding: 6px 8px;
    align-self: center;

    &--restore {
      background-color: $default-tile-color;
      color: $primary-color;
    }

    &--unblock {
      background-color: $primary-light-red;
      color: $primary-text-red;
    }
  }

  &__back-button {
    background-color: $transparent-color;
    outline: none;
    border: none;
    color: $secondary-icon-color;
    cursor: pointer;
    margin-right: 18px;

    @include tablet {
      display: none;
    }
  }
}
