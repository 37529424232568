@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-tab {
  border: none;
  padding: 7px 0;
  color: $primary-tab-inactive-color;
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  position: relative;
  cursor: pointer;

  &--active {
    color: $primary-color;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      background-color: $primary-color;
      bottom: 0;
      left: 0;
      transform: translateY(1px);
    }
  }

  &--new-job {
    @include xga() {
      margin-right: 23px;
      padding-bottom: 16px;
    }
  }
}
