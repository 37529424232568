@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.messaging-message {
  $root: &;

  display: flex;
  flex-direction: row;
  margin-top: 36px;

  .messaging-avatar {
    margin-right: 8px;
  }

  &__content {
    @include xga() {
      max-width: 70%;
    }
  }

  &--incoming {
    display: grid;
    grid: 1fr / auto 1fr;
  }

  &--outgoing {
    #{$root}__content {
      margin-left: auto;
    }
  }

  .messaging-dot-menu {
    display: none;
    align-self: center;

    &.is-active {
      display: block;
    }
  }

  &:hover {
    .messaging-dot-menu {
      display: block;
    }
  }
}
