@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.recruiter-type-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin: 97px 0 39px;

    @include xga() {
      margin: 54px 0 58px;
    }

    @include wxgaplus() {
      margin: 74px 0 105px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 33px;
    text-align: center;
    padding: 0 0 6px 0;

    color: $primary-title-color;

    @include xga() {
      line-height: 39px;
      font-size: 1.75rem;
    }
  }

  &__description {
    font-weight: 300;
    line-height: 22px;
    text-align: center;

    color: $primary-description-color;

    @include xga() {
      line-height: 25px;
      font-size: 1.125rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 86px;

    @include xga {
      flex-direction: row;
      margin-bottom: 80px;
    }

    @include wxgaplus() {
      margin-bottom: 140px;
    }
  }

  &__radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 14px;
    justify-content: flex-end;
    border-radius: 17px;
    width: 315px;
    height: 271px;
    cursor: pointer;
    border: 2px solid $border-secondary-color;
    background-color: $default-background-color;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &--active {
      cursor: default;
      box-shadow: 0 3px 10px rgba(107, 154, 225, 0.26);

      background-color: $secondary-background-color;
    }

    &:first-child {
      margin-bottom: 32px;

      @include xga() {
        margin-right: 85px;
        margin-bottom: 0;
      }
    }

    label {
      padding-top: 19px;
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 50px;

    @include xga() {
      line-height: 25px;
      font-size: 1.125rem;
    }
  }

  &__alternative {
    margin: 6px 0 0;
    font-weight: 300;
    text-align: center;
    line-height: 1.375rem;

    a {
      margin: 0 0 0 2px;
      color: $primary-color;
      text-decoration: underline;
    }

    @include xga {
      margin: 20px 0 0;
    }
  }
}
