@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.add-billing-contact {
  &__title {
    font-size: 1.125rem;
    color: $default-label-color;
    margin-bottom: 28px;
  }

  &__button {
    cursor: pointer;
    border: none;
    background-color: $secondary-label-color;
    width: 100%;
    padding: 8px;
    font-weight: 500;
    font-size: 1rem;
    color: $primary-btn-text-color;
    border-radius: 6px;

    &--active {
      background-color: $primary-color;
    }

    @include xga() {
      width: max-content;
      padding: 10px 20px;
      max-height: 40px;
      font-size: 0.875rem;
    }
  }

  &__content {
    @include xga() {
      display: grid;
      grid: 1fr / 1fr 1fr 161px;
      gap: 0 20px;
    }
  }
}
