@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-benefits {
  margin-top: 33px;
  padding: 0 13px;

  @include xga() {
    padding-left: 41px;
  }

  &__container {
    margin-top: 13px;
    margin-bottom: 35px;

    & > div {
      margin-bottom: 25px;
    }

    @include xga() {
      max-width: 522px;

      & > div {
        margin-bottom: 27px;
      }
    }
  }

  &__header {
    padding: 13px 13px 20px;
    color: $default-label-color;
    border-top: 1px solid $border-default-color;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
  }

  &__description {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    opacity: 0.5;
  }
}
