@import './responsiveness';
@import './Colors';

$menu-items: 6;
$menu-height-mobile: 56px;
$menu-height: 84px;
$menu-width: 234px;
$menu-item-width: 22px;
$menu-item-height: 24px;
$menu-item-padding-top: 22px;

$navigation-height: 103px;

.dashboard {
  width: 100%;
  position: relative;

  &__head {
    top: 0;
    left: 0;
    z-index: 7;
    width: 100%;
    position: fixed;

    height: $navigation-height;
    background-color: $default-background-color;
  }

  &__content {
    padding: $navigation-height 0 $menu-height;

    @include xga {
      padding: $navigation-height 34px 0 0;
    }
  }

  &__menu {
    left: 0;
    bottom: 0;
    z-index: 7;
    width: 100%;
    position: fixed;

    height: $menu-height-mobile;

    @include tablet {
      height: $menu-height;
    }

    &--hidden {
      display: none;
    }

    @include xga {
      top: 0;
      height: 100vh;
      bottom: unset;

      width: $menu-width;

      &--hidden {
        display: initial;
      }
    }

    @include wxgaplus {
      left: calc((100vw - 1440px) / 2);
    }
  }

  &__container {
    margin: 0 auto;
    max-width: 1440px;
    position: relative;

    @include xga {
      min-height: calc((100vh + 1px));
      padding: 0 0 0 $menu-width;
    }
  }
}

.dashboard-head {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 28px 15px 28px 16px;
  justify-content: space-between;

  @include xga {
    margin: 0 auto;
    max-width: 1440px;

    padding: 40px 38px 32px $menu-width;
  }

  &__title {
    font-weight: 500;
    line-height: 47px;
    font-size: 2.125rem;

    color: $primary-black;

    @include xga {
      font-size: 1.5rem;
      line-height: 1.5rem;
      letter-spacing: -0.2px;

      color: $primary-text-color;
    }
  }

  &__actions {
    display: flex;
    user-select: none;
    position: relative;
    align-items: center;
    -webkit-tap-highlight-color: #00000000;

    img {
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }

  &__action {
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    align-items: center;
    justify-content: center;

    @include xga {
      margin-left: 28px;
    }

    &--rounded {
      overflow: hidden;
      border-radius: 50%;
    }

    &--not-empty {
      &:before {
        top: 2px;
        right: 2px;
        width: 16px;
        height: 16px;
        display: flex;
        line-height: 0.9;
        font-weight: 700;
        font-size: 0.5rem;
        border-radius: 50%;
        align-items: center;
        position: absolute;
        justify-content: center;
        content: attr(data-notifications);
        font-family: Arial, Helvetica, sans-serif;

        color: $secondary-text-color;
        background-color: $primary-red;
      }
    }

    &--default-avatar {
      background-color: $secondary-btn-color;

      i::before {
        color: $primary-color;
      }
    }

    i {
      transition: 0.2s color;

      color: $primary-description-color;
    }

    &:hover i {
      color: $primary-color;
    }
  }

  &__profile-menu {
    right: 0;
    top: 42px;
    z-index: 3;
    opacity: 0;
    perspective: 1000;
    position: absolute;
    transition: all 0.4s;
    backface-visibility: hidden;
    transform: translateX(120%) translate3d(0, 0, 0);

    &--open {
      opacity: 1;
      transform: translateX(0) translate3d(0, 0, 0);
    }

    .profile-menu {
      padding: 10px;
      border-radius: 6px;
      background: $secondary-background-color;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.14);

      &__item {
        width: 186px;
        height: 40px;
        display: flex;
        padding: 0 4px;
        font-weight: 300;
        font-size: 1.125rem;
        align-items: center;

        border-bottom: 1px solid $border-default-color;

        &:last-child {
          border: none;
        }

        a {
          display: flex;
          align-items: center;
          transition: 0.2s color;

          &:hover {
            color: $primary-color;
          }
        }

        i {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  &__notifications {
    right: 0;
    top: 102px;
    opacity: 0;
    width: 100%;
    position: fixed;
    perspective: 1000;
    transition: all 0.4s;
    backface-visibility: hidden;
    transform: translateX(120%) translate3d(0, 0, 0);

    &--open {
      opacity: 1;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      transform: translateX(0) translate3d(0, 0, 0);

      background-color: $primary-card-background;
    }

    @include xga() {
      top: 42px;
      left: unset;
      width: 490px;
      border-radius: 6px;
      position: absolute;
    }
  }
}

.dashboard-menu {
  width: 100%;
  height: 100%;

  background-color: $menu-background-color;

  @include xga {
    background-color: unset;
  }

  &__title {
    display: none;
    padding: 14px 0 0 34px;

    color: $primary-black;

    @include xga {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 74px;

      span {
        font-weight: 400;
      }
    }
  }

  &__content {
    padding: 18px 0 0;
    position: relative;

    @include xga {
      padding: 0 0 0 20px;
    }

    &:before {
      --height: 2px;

      top: 0;
      left: 0;
      display: block;
      position: absolute;
      height: var(--height);
      transition: 0.2s ease-out;

      background-color: $primary-color;

      @include xga {
        --height: 40px;

        left: 20px;
        opacity: 0.1;
        width: 194px;
        border-radius: 4px;
      }
    }

    @for $i from 1 through $menu-items {
      &--#{$i}:before {
        --width: 52px;
        --icons: calc(22px * (#{$i} - 1));
        --space: calc((100vw - (#{$menu-item-width} * #{$menu-items})) / (#{$menu-items} + 1));

        content: '';
        width: var(--width);
        transform: translateX(
          calc((var(--space) * #{$i}) + var(--icons) + (#{$menu-item-width} / 2) - (var(--width) / 2))
        );

        @include xga {
          --width: 194px;
          --space: calc(#{$menu-item-padding-top} * #{$i});
          --icons: calc(#{$menu-item-height} * (#{$i} - 1));

          transform: translateY(calc(var(--space) + var(--icons) - ((var(--height) - #{$menu-item-height}) / 2)));
        }
      }
    }
  }

  &__list {
    height: 100%;
    display: flex;
    position: relative;
    align-content: center;
    justify-content: space-evenly;

    @include xga {
      margin: 0;
      display: block;
      padding: 0 0 0 14px;
    }
  }

  &__item {
    @include xga {
      width: 100%;
      display: block;

      padding: $menu-item-padding-top 0 0;
    }
  }

  &__link {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include xga {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      height: $menu-item-height;
    }

    i {
      display: block;
      font-size: 22px;
      transition: 0.2s color;

      @include xga {
        font-size: 16px;
        padding: 0 10px 0 0;
      }
    }

    &--active i,
    &--active span {
      color: $primary-color;
    }
  }

  &__label {
    display: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    transition: 0.2s color;

    @include xga {
      display: initial;
    }
  }

  &__badge {
    display: none;

    @include xga {
      display: initial;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      background-color: $primary-color;
      color: $secondary-text-color !important;
      text-align: center;
      border-radius: 100px;
      margin-left: auto;
      margin-right: 25px;
    }
  }
}
