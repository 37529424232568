@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.user-rating {
  @include wxga() {
    grid-row: 3/4;
    grid-column: 1/2;
    padding: 46px 53px 0 0;
  }

  &__title {
    font-size: 1.438rem;
    color: $primary-text-color;
    margin-bottom: 30px;
  }

  &__rating {
    display: flex;
    align-items: flex-end;
    gap: 0 13px;

    .rating-star {
      font-size: 2.625rem;

      &--empty {
        color: $tertiary-inactive-color;
      }

      &--filled {
        color: $primary-color;
      }
    }
  }

  &__rate-number {
    font-size: 1.125rem;
    color: $primary-text-color;
  }
}
