@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.contact-card {
  display: flex;
  border-radius: 8px;
  background-color: $primary-card-background;
  gap: 16px;
  padding: 18px 13px 18px 18px;
  align-items: center;
  border: 1px solid $primary-card-background;

  &--selected {
    border-color: $primary-color;
  }

  &--clickable {
    cursor: pointer;
  }

  &__photo {
    border-radius: 27px;
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: unset;
      object-fit: cover;
    }

    span {
      display: none;
    }

    &--fake {
      background-color: $primary-color;

      span {
        display: initial;
        font-weight: 500;
        font-size: 0.875rem;
        color: $secondary-text-color;
      }

      img {
        display: none;
      }
    }
  }

  &__info {
    display: grid;
    grid: 0fr 0fr / 2fr 1fr;
    gap: 3px 9px;
  }

  &__name {
    grid-column: 1/2;
    grid-row: 1/2;
    color: $default-label-color;
    font-weight: 500;
    font-size: 0.875rem;
  }

  &__rating {
    grid-column: 2/3;
    grid-row: 1/2;
    color: $primary-rating-color;
    font-weight: 500;
    font-size: 0.875rem;

    i {
      margin-right: 5px;
      font-size: 0.688rem;
    }
  }

  &__recruiter-type {
    grid-column: 1/3;
    grid-row: 2/3;
    color: $description-color;
    font-size: 0.875rem;
  }

  &__message {
    margin-left: auto;

    button {
      background-color: $default-tile-color;
      color: $primary-color;
      border: none;
      padding: 11px 13px;
      border-radius: 6px;
      font-size: 0.875rem;
      cursor: pointer;

      span {
        display: none;
      }
    }

    &--text {
      @include wxga() {
        button {
          padding: 15px 35px;
          font-weight: 500;
          font-size: 1.125rem;
          span {
            display: initial;
          }

          i {
            display: none;
          }
        }
      }
    }
  }
}
