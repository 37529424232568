@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.wallet-tile {
  display: flex;
  justify-content: space-between;
  background-color: $primary-card-background;
  border-radius: 8px;
  padding: 28px 16px 24px;
  cursor: pointer;

  &__title,
  &__value {
    font-size: 1rem;
    color: $default-label-color;
  }
}
