@import 'src/ui/scss/Colors';

.messaging-boxes {
  background-color: $primary-card-background;
  border-radius: 6px;
  box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
  height: 100%;

  &__header {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    padding: 12px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primary-black;

    & .dropdown {
      margin-left: auto;
    }

    &-title {
      font-size: 24px;
      font-weight: 500;
    }

    &-back {
      margin-right: 10px;
      i {
        font-size: 18px;
        vertical-align: middle;
      }
    }

    button {
      cursor: pointer;
      border: none;
      background-color: $transparent-color;
    }
  }
}
