@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-benefits {
  @include xga() {
    display: grid;
    grid-template-rows: 0fr 0fr;
    grid-template-columns: 1fr 1fr;
    max-width: 750px;
  }

  &__benefit {
    border-bottom: 1px solid $border-default-color;

    padding: 22px 12px 25px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 100px;

      @include xga() {
        padding-bottom: 160px;
      }
    }

    @include xga() {
      padding: 30px 26px 37px;

      &:first-child,
      &:nth-child(2) {
        padding-top: 47px;
      }
    }
  }

  &__label {
    font-size: 1.125rem;
    color: $default-label-color;
    margin: 0 0 18px;
  }

  &__badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__badge {
    font-size: 1rem;
    font-weight: 300;
    color: $primary-color;
    background-color: $primary-light-grey;
    padding: 0 16px;
    margin: 0 16px 9px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
