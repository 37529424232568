@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

$root: 'base-tiny';

.base-tiny {
  display: flex;
  flex-direction: column;

  &__label {
    order: 1;
    font-weight: 300;
    margin-left: 4px;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    & > sup {
      color: red;
    }
  }

  &__message {
    order: 3;
    height: 20px;
    font-weight: 300;
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    &--error {
      color: $primary-red;
    }
  }
}
