@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-tabs {
  &__navigation {
    display: flex;
    justify-content: space-between;
    padding: 0 11px;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      justify-content: flex-start;
      width: 71%;
      gap: 0 41px;
    }
  }

  &--new-job > #{&}__navigation {
    @include xga() {
      padding: 0 38px;
    }
  }
}
