@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.total-votes {
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  @include wxga() {
    grid-row: 3/5;
    grid-column: 2/3;
    padding: 0 0 0 46px;
    margin-top: 46px;
    border-left: 1px solid $border-default-color;
  }

  &__title {
    font-size: 1.438rem;
    color: $primary-text-color;
    margin-bottom: 40px;

    @include wxga() {
      margin-bottom: 30px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px 0;

    @include wxga() {
      gap: 14px 0;
      max-width: 440px;
    }
  }

  &__rating {
    display: grid;
    grid: 1fr 0fr / 1fr 1fr;

    @include wxga() {
      gap: 7px 0;
    }
  }

  &__label,
  &__value {
    font-weight: 300;
    font-size: 1rem;
    color: $default-label-color;

    @include wxga() {
      font-size: 1.125rem;
    }
  }

  &__label {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  &__value {
    grid-row: 1/2;
    grid-column: 2/3;
    justify-self: end;
  }
}
