@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.notification-card {
  gap: 0 12px;
  display: grid;
  transition: all 0.2s;
  padding: 21px 0 21px 9px;
  grid: repeat(3, auto) / 1fr 0fr;

  border-bottom: 1px solid $tertiary-disabled-color;

  &:first-child {
    padding-top: 0;
  }

  @include xga() {
    padding: 17px 6px 6px;
  }

  &:hover {
    opacity: 1;
  }

  &--read {
    opacity: 0.4;
  }

  &--page {
    &:last-child {
      @include xga() {
        border-bottom: none;
      }
    }
  }

  &--page &__clear {
    @include xga() {
      align-self: end;
      margin-bottom: 20px;
    }
  }

  &--page &__container {
    margin-right: 0;
  }

  &__title {
    grid-row: 1/2;
    grid-column: 1/2;
    font-size: 1.125rem;
    color: $default-label-color;
    margin-bottom: 4px;
  }

  &__description {
    grid-row: 2/3;
    grid-column: 1/2;
    color: $notification-text-color;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 17px;

    @include xga() {
      margin-bottom: 9px;
    }

    a {
      color: $primary-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    ul,
    ol {
      list-style: initial;
      margin: initial;
      padding-left: 30px;
    }

    ul > li,
    ol > li {
      margin: unset;
      padding: unset;
    }
  }

  &__clear {
    grid-row: 1/3;
    grid-column: 2/3;
    color: $tertiary-disabled-color;
    font-size: 0.688rem;
    align-self: center;

    i {
      cursor: pointer;
    }
  }

  &__container {
    grid-row: 3/4;
    grid-column: 1/3;
    display: flex;
    margin-left: auto;
    gap: 0 7px;

    @include xga() {
      margin-right: 23px;
    }
  }

  &__badges {
    display: flex;
    gap: 0 7px;
  }

  &__badge {
    height: 22px;
    display: flex;
    min-width: 56px;
    font-weight: 300;
    font-size: 0.875rem;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;

    color: $primary-badge-color;
    background-color: $primary-badge-background-color;

    &--new {
      background-color: $secondary-badge-background-color;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 0 3px;
    color: $description-color;
    font-weight: 300;
    font-size: 1rem;

    i {
      font-size: 0.688rem;
    }
  }
}
