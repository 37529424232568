@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-talent-progress {
  background-color: $secondary-background-color;

  @include xga() {
    margin: 0 10px 0 0;
    border-radius: 6px;
  }

  &__title {
    display: none;

    @include xga() {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $primary-text-color;
      border-bottom: 1px solid $border-default-color;
      padding: 12px 20px;
    }
  }

  &__container {
    display: grid;
    position: relative;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    @include xga() {
      padding: 10px;
      display: flex;
      grid-auto-flow: unset;
      flex-direction: column;
      grid-auto-columns: unset;
    }
  }
}
