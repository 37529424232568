@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.billing-info {
  display: flex;
  flex-direction: column;
  padding: 31px 16px 45px;

  @include xga() {
    padding: 30px 44px 27px;
  }

  &__header {
    margin-bottom: 16px;

    @include xga() {
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include xga() {
      display: none;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 1.125rem;
    color: $secondary-description-color;

    span {
      cursor: pointer;
      color: $primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content {
    @include xga() {
      display: grid;
      grid: repeat(5, 1fr) / repeat(2, 1fr);
      gap: 0 26px;
    }
  }

  &__company {
    grid-column: 1/3;
    grid-row: 2/3;
  }

  &__button {
    background-color: $primary-color;
    color: $primary-btn-text-color;
    border-radius: 6px;
    padding: 15px 35px;
    font-weight: 500;
    font-size: 1.125rem;
    align-self: flex-end;
    border: none;
    cursor: pointer;
  }
}
