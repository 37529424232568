@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-description {
  padding: 0 13px;

  &__container {
    margin-top: 25px;

    & > * {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 31px;
      }
    }
  }

  &__job-details {
    margin-bottom: 20px;

    & > * {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include xga() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 0fr 0fr 0fr;
      gap: 20px 35px;
      margin-bottom: 13px;

      & > * {
        margin-bottom: 0;
      }
    }
  }

  &__salary {
    display: flex;
    flex-direction: column;
  }

  &__field-group {
    display: flex;
    & div:first-child {
      margin-right: 6px;
    }
  }

  &__cards {
    @include xga {
      max-width: 522px;
    }
  }

  &__card {
    margin: 0 0 22px;
  }
}
