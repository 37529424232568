@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.messaging-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  align-self: center;
  width: 40px;
  height: 40px;

  &__status-indicator {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: $primary-btn-text-color;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    padding: 2px;

    &--has-new-messages {
      background-color: $default-tile-color;
    }
  }

  &__status {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &--active {
      background-color: $messaging-active;
    }

    &--away {
      background-color: $messaging-away;
    }

    &--inactive {
      background-color: $messaging-inactive;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: $secondary-text-color;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
