@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.contact-list {
  @include wxga() {
    overflow: hidden;
    margin-right: 10px;
    border-radius: 6px;
    border-radius: 6px;
    height: fit-content;
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
  }

  &__header {
    display: grid;
    grid: 0fr 0fr / 1fr 1fr;
    background: $primary-card-background;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    padding: 14px 15px 8px 18px;
    gap: 11px 0;

    @include wxga() {
      gap: 17px 0;
    }
  }

  &__head {
    color: $primary-text-color;
    font-weight: 500;
    font-size: 1.5rem;
    grid-row: 1/2;
    grid-column: 1/2;

    i {
      display: none;
    }

    @include wxga() {
      font-size: 0.875rem;

      i {
        display: initial;
        margin-right: 10px;
      }
    }
  }

  &__search {
    grid-row: 1/2;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-self: end;

    input {
      border: none;
    }

    i {
      color: $primary-description-color;
      font-size: 0.875rem;
    }
  }

  &__filter {
    grid-row: 2/3;
    grid-column: 1/3;
    display: flex;
    gap: 0 9px;
    align-items: center;
    font-weight: normal;
    color: $primary-filer-color;
  }

  &__label {
  }

  &__dropdown {
    width: 145px;

    .filter-select {
      &__control {
        background-color: $primary-card-background;
        color: $primary-text-color;
        border: none;
        min-height: initial;
        box-shadow: none;
        padding: 2px 8px 2px 6px;
      }

      &__indicator {
        padding: 0;
      }

      &__indicator-separator {
        display: none;
      }

      &__container {
        padding: 0;
      }

      &__placeholder,
      &__single-value {
        font-size: 1rem;
        color: $primary-text-color;
      }

      &__value-container {
        padding: 0;

        & > div {
          margin: 0;
          padding: 0;
        }
      }

      &__menu {
        border: none;
        box-shadow: 0 6px 18px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
      }

      &__menu-list {
        padding-top: 0;
      }

      &__option {
        color: $primary-description-color;
        font-weight: 300;
        font-size: 1rem;
        padding: 7px 11px;

        &--is-selected {
          background-color: $primary-card-background;
          color: $default-label-color;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    padding: 22px 13px;
  }
}
