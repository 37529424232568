@import './Colors.scss';

$default-border-radius: 6px;
$block-border-radius: 20px;
$border-block: 1px solid $border-default-color;
$company-logo-size: 64px;

.width-100 {
  width: 100%;
}

.has-border-bottom {
  border-bottom: 2px solid #f5f5f5;
}

.has-border-bottom-4 {
  border-bottom: 4px solid #f5f5f5;
}

.error {
  border: 1px solid red !important;
  border-radius: 5px;
}

.padding-bottom {
  padding-bottom: 40px;
}

.gray-text {
  color: #666666;
}

.margin-center {
  margin: 0px auto;
}
