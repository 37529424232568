@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-commission {
  padding: 73px 13px 0;

  @include xga() {
    padding-top: 121px;
  }

  &__container {
    margin: 0 0 72px;

    @include xga() {
      padding: 0 74px 0;
      margin: 0 0 210px;
    }
  }
}
