@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.billing-contacts {
  padding: 30px 14px 70px;

  @include wxga() {
    padding: 21px 200px 21px 50px;
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include xga() {
      font-size: 1.125rem;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 1rem;
    color: $label-lighter;
  }

  &__content {
    margin: 15px 0 47px;
  }
}
