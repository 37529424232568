@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.wallet {
  background-color: $secondary-background-color;

  @include xga() {
    padding-top: 22px;
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
    border-radius: 6px;
  }
}
