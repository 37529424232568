@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.invoice-search {
  display: flex;
  background-color: $default-background-color;
  align-items: center;
  border: 1px solid $secondary-border-color;
  padding-left: 10px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;

  &__input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    padding: 12px;
    border: none;
    background-color: $default-background-color;
  }
}
