@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.saved-talent {
  display: flex;
  padding: 26px 0;
  align-items: center;
  border-bottom: 1px solid $border-default-color;

  &:last-child {
    border-bottom: none;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    margin: 0 14px 0 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  &__container {
    gap: 0 16px;
    display: flex;
    align-items: center;
  }

  &__personal {
    width: 208px;
  }

  &__salary,
  &__job {
    width: 116px;
    display: none;

    @include xga {
      display: block;
    }
  }

  &__row {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.125rem;

    color: $secondary-description-color;

    a {
      text-decoration: underline;

      color: $primary-link-color;
    }
  }

  &__value {
    display: flex;
    align-items: center;

    @include xga {
      max-width: 116px;
    }

    &--separator {
      display: none;
      margin: 0 0 0 8px;
      padding: 0 0 0 8px;

      border-left: 1px solid $secondary-description-color;

      @include xga {
        display: block;
      }
    }

    &--bold {
      display: flex;
      line-height: 1;
      font-weight: 500;
      min-height: 22px;
      padding: 0 0 2px;
      align-items: center;
    }

    &--black {
      color: $primary-title-color;
    }
  }

  &__label {
    height: 22px;
  }

  &__actions {
    display: flex;
    margin-left: auto;
    gap: 0 9px;
  }

  &__button {
    background: $secondary-green;
    border-radius: 6px;
    color: $primary-btn-text-color;
    padding: 16px;
    border: none;
    font-size: 0.688rem;
    cursor: pointer;

    span {
      display: none;
    }

    &--email {
      display: none;
    }

    @include xga() {
      padding: 5px 12px;
      font-weight: 500;
      font-size: 0.875rem;

      span {
        display: initial;
      }

      i {
        display: none;
      }

      &--email {
        display: initial;
        color: $primary-color;
        background-color: $primary-light-grey;
      }
    }
  }
}
