@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-search-result-card {
  border: 1px solid $secondary-border-color;
  padding: 7px;
  margin-right: 24px;
  margin-bottom: 10px;
  width: 245px;
  flex-shrink: 0;

  @include wxga() {
    margin-right: 41px;
  }

  &--result {
    width: 343px;
    margin-right: 0;

    @include xga() {
      width: 245px;
    }

    margin-bottom: 14px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 65px;
    height: 65px;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    border: 1px solid $border-default-color;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &:empty {
      position: relative;

      background-color: $default-logo-background-color;

      &::after {
        top: 50%;
        left: 50%;
        width: 10px;
        content: '';
        height: 46px;
        position: absolute;
        transform: translate(-50%, -50%) skew(20deg);

        background-color: $primary-black;
      }
    }
  }

  &__title {
    margin-bottom: 13px;
  }

  &__name {
    font-weight: 500;
    font-size: 1.125rem;
    color: $default-label-color;
  }

  &__location {
    font-weight: 300;
    font-size: 1rem;
    color: $secondary-description-color;
  }

  &__job-info {
    padding-left: 10px;
    color: $default-label-color;

    ul {
      list-style: disc inside;

      li {
        font-weight: 300;
        font-size: 1rem;

        &::marker {
          font-size: 0.5rem;
        }

        &:last-child {
          margin-bottom: 13px;
        }
      }
    }
  }

  &__creation-date {
    font-weight: 300;
    font-size: 1rem;
    color: $default-label-color;

    span {
      color: $primary-color;
    }
  }

  &__actions {
    display: flex;
    gap: 0 13px;

    .spires-icon-heart-filled {
      font-size: 1.063rem;
    }
  }

  &__icon {
    cursor: pointer;
    font-size: 1.125rem;
    color: $tertiary-description-color;

    &--filled {
      color: $primary-color;
    }
  }
}
