@import '../../../../ui/scss/Colors';
@import '../../../../ui/scss/responsiveness';

.select-dialog {
  &__message {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.8;
    margin: 0 0 40px;
  }

  &__select {
    margin-bottom: 10px;
  }

  &__actions {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: center;
  }

  &__button {
    opacity: 1;
    height: 55px;
    border: none;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    padding: 16px 36px;
    border-radius: 6px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    align-items: center;
    transition: 0.2s opacity;
    justify-content: center;

    &--secondary {
      color: $primary-color;
      background-color: $default-tile-color;
    }

    &--tertiary {
      color: $primary-text-red;
      background-color: $primary-light-red;
    }

    &--quinary {
      color: $secondary-text-color;
      background-color: $primary-text-red;
    }

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
