@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.talent-info {
  &__fields {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 35px 13px;

    @include wxga() {
      display: grid;
      grid: repeat(3, 0fr) / repeat(2, 1fr);
      gap: 6px 35px;
      padding: 30px 11px 15px;
    }
  }

  &__field {
    &--full-width {
      @include xga {
        grid-column: 1/3;
      }
    }
  }

  &__photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px 0;
    margin: 0 13px;
    padding: 57px 0;
    border-radius: 4px;

    border: 1px solid $border-default-color;

    input {
      display: none;
    }

    @include xga() {
      gap: 6px 0;
    }
  }

  &__label {
    font-size: 1rem;
    color: $default-label-color;
  }

  &__description {
    display: none;

    @include wxga() {
      display: initial;
      color: $tertiary-label-color;
      font-size: 0.875rem;
      margin-bottom: 12px;
    }
  }

  &__upload {
    border: none;
    color: $primary-color;
    background: $secondary-btn-color;
    border-radius: 6px;
    padding: 10px 22px 10px 20px;
    display: flex;
    gap: 0 14px;
    align-items: center;
    font-size: 1rem;

    @include wxga() {
      background-color: $default-tile-color;
      font-weight: 500;

      i {
        display: none;
      }
    }
  }
}
