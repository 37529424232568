@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-search-filters {
  margin-bottom: 22px;

  @include wxga() {
    margin-bottom: 0;
  }

  &__field {
    display: flex;
    cursor: pointer;
    padding: 9px 12px;
    align-items: center;

    border: 1px solid $secondary-border-color;
    background-color: $default-background-color;

    span {
      margin-left: 16px;
      width: 100%;
    }

    @include wxga() {
      width: 108px;
    }

    @include wxgaplus() {
      width: 122px;
    }
  }

  &__modal ~ #{&}__field {
    display: none;

    @include wxga() {
      display: flex;
    }
  }

  &__icon {
    &--collapse {
      transform: rotate(90deg);
      @include wxga() {
        display: none;
      }
    }
  }
}
