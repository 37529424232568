@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-tabs-routes {
  $tab-margin-x: 20px;
  $tab-padding-x: 20px;
  $tab-max-width: 94px;

  &__navigation {
    display: flex;
    overflow: hidden;
    position: relative;

    border-bottom: 1px solid $border-default-color;
  }

  &__tab {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
    position: relative;
    line-height: 1.25rem;
    padding: 0 16px 14px;
    transition: 0.4s color;

    color: $description-color;

    @include xga {
      margin: 0 $tab-margin-x;
      padding: 0 $tab-padding-x 14px;
    }

    &::after {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      transition: 0.2s transform;
      transform: translateY(2px);

      background-color: $primary-color;
    }

    &--active {
      color: $primary-color;

      &::after {
        transform: translateY(0);
      }
    }

    &:hover {
      color: $primary-color;
    }
  }
}
