@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.invoices {
  padding: 31px 16px 80px;

  @include wxga() {
    padding: 21px 100px 50px 47px;
  }

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include xga() {
      font-size: 1.125rem;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 1rem;
    color: $label-lighter;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 14px 0;
  }

  &__search {
    margin: 21px 0 35px;

    @include xga() {
      max-width: 456px;
    }
  }

  &__labels {
    display: none;

    @include xga() {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
      padding: 0 14px 0;
    }
  }

  &__label {
    font-weight: 300;
    font-size: 0.874rem;
    color: $label-lighter;
  }

  &__no-data {
    color: $description-color;
    font-size: 1rem;
    margin: 20px;
  }
}
