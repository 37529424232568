@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

$root: '.base-status-select';

#{$root} {
  width: 108px;

  #{$root} {
    &--is-disabled {
      #{$root}__control {
        background-color: $primary-light-red;

        #{$root}__single-value {
          color: $primary-text-red;
        }

        #{$root}__dropdown-indicator {
          visibility: hidden;
        }
      }
    }

    &__control {
      border: none;
      min-height: 32px;
      border-radius: 6px;
      letter-spacing: -0.2px;

      color: $primary-color;
      background-color: $default-tile-color;
    }

    &__placeholder {
      color: $primary-color;
    }

    &__value-container {
      padding: 2px 0 2px 8px;
    }

    &__dropdown-indicator {
      padding: 6px;

      color: $primary-color;
    }

    &__single-value {
      margin: 0;
      font-size: 0.875rem;

      color: $primary-color;
    }
  }
}
