@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-language-card {
  border-radius: 6px;
  padding: 12px 8px 20px;
  box-shadow: 0 6px 18px rgba(62, 56, 106, 0.03);

  border: 1px solid $border-default-color;

  &--search {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  &--search #{&}__header {
    color: $default-label-color;
    margin-bottom: 24px;
  }

  &__header {
    margin: 0 0 36px;
    padding: 0 0 0 4px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;

    color: $primary-color;
  }

  &__description {
    text-align: center;
    margin-bottom: 14px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $default-label-color;
    opacity: 0.5;

    @include xga() {
      font-size: 18px;
    }
  }

  &__search {
    margin-bottom: 24px;

    @include xga {
      padding: 0 36px;
    }
  }

  &__languages {
    @include xga {
      padding: 0 36px;
    }
  }
}
