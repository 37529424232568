@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-button {
  font-family: 'Halyard Display', sans-serif;
  background-color: $primary-color;
  border-radius: 6px;
  border: 1px solid $border-default-color;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: $primary-btn-text-color;
  padding: 8px 20px;
  transition: 0.2s all;

  &--medium {
    padding: 12px 30px;
  }

  &--large {
    font-weight: 400;
    padding: 12px 44px;
    font-size: 1.125rem;
    line-height: 1.5625rem;

    @include xga {
      padding: 15px 35px;
      font-size: 1.25rem;
      line-height: 2.0625rem;
    }
  }

  &--disabled {
    background-color: $primary-disabled-color;
    color: $default-message-color;
  }

  &--secondary {
    background-color: $secondary-btn-color;
    color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: $primary-btn-text-color;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  &--registration {
    min-width: 118.05px;
    height: 33.75px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0 11px;

    @include xga() {
      height: 55px;
      padding: 15px 34px;
      font-size: 1.125rem;
    }
  }
}
