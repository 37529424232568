@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.talentpool-filter {
  &__button {
    display: flex;
    background-color: $default-background-color;
    border: 1px solid $border-default-color;
    padding: 5px 10px;
    align-items: center;

    &:after {
      position: relative;
      content: '';
      top: 3px;
      margin-left: 13px;
      border: 6px solid;
      border-color: #000 transparent transparent transparent;
    }
  }

  &__modal {
    display: none;

    &--visible {
      display: block;
      position: absolute;
      z-index: 1;
      background-color: $primary-card-background;
      border: 1px solid $border-default-color;
    }
  }

  &__options {
    display: flex;
    overflow-y: auto;
    max-height: 256px;
    flex-direction: column;
  }

  &__option {
    display: flex;
    gap: 0 5px;
    align-items: center;
    padding: 5px 10px;
  }

  &__indicator {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid $primary-color;
  }

  &__option--selected > &__indicator {
    border-width: 5px;
  }

  &__option--multi > &__indicator {
    border-radius: 0;
  }

  &__option--multi.talentpool-filter__option--selected > &__indicator {
    border-width: 1px;
    background-color: $primary-color;

    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 10px;
      margin: 3px 6px;
      border-bottom: 2px solid $secondary-text-color;
      border-right: 2px solid $secondary-text-color;
      transform: rotate(45deg);
    }
  }
}
