@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.talent-additional-info {
  padding: 25px 18px 30px 13px;

  @include wxga() {
    display: grid;
    grid: repeat(4, 0fr) / 1fr 1fr;
    gap: 22px 37px;
  }
  &__salary,
  &__commission {
    display: grid;
    grid: 0fr 0fr / 1fr 1fr;
    gap: 4px 6px;
  }

  &__commission {
    grid: 0fr 0fr/234px 1fr;

    @include xga {
      grid-column: 1/3;
      grid: 0fr 0fr/282px 108px;
    }
  }

  &__label {
    grid-column: 1/3;
    font-weight: 300;
    font-size: 0.875rem;
    color: $secondary-label-color;
  }

  &__info {
    grid-row: 4/5;
    grid-column: 1/3;
  }
}
