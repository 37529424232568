@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.payments-and-refunds {
  padding: 31px 16px 80px;

  @include wxga() {
    padding: 31px 56px 20px;
  }

  &__header {
    font-weight: 500;
    font-size: 1.5rem;
    color: $default-label-color;

    @include wxga() {
      display: none;
    }
  }

  &__content {
    @include wxga() {
      display: grid;
      grid: repeat(3, 0fr) / 2fr 1fr;
      gap: 0 90px;
    }
  }

  &__title {
    font-size: 1.125rem;
    color: $default-label-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    color: $primary-color;
    font-size: 0.938rem;
    width: 37px;
    height: 37px;
    background-color: $default-tile-color;
    cursor: pointer;
    visibility: visible;

    &--hidden {
      visibility: hidden;
    }

    &--mobile-only {
      @include xga {
        display: none;
      }
    }

    &:hover {
      color: $primary-color;
    }
  }

  &__card {
    margin-bottom: 30px;

    @include wxga() {
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

  &__other {
    @include wxga() {
      grid-row: 2/3;
      grid-column: 1/2;
    }
  }

  &__payouts {
    @include wxga() {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }

  &__methods {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    margin-bottom: 54px;

    @include wxga() {
      gap: 0 0;
    }
  }

  &__labels {
    display: none;

    @include wxga() {
      display: grid;
      grid: 1fr / 0fr 3fr 2fr 1fr 1fr;
      font-weight: 300;
      font-size: 1rem;
      color: $secondary-label-color;
    }
  }

  &__name {
    grid-column: 3/4;
  }

  &__expiration {
    grid-column: 4/5;
  }

  &__visualization {
    margin-top: 10px;
    position: relative;
    font-size: 1.125rem;
    color: $secondary-text-color;
    width: 343px;
    height: 180px;
    border-radius: 6px;
    background: linear-gradient(45deg, $primary-dark-grey 0%, $secondary-light-grey 70%);
    overflow: hidden;

    @include xga() {
      width: 409px;
      height: 220px;
    }

    &::before {
      content: '';
      width: 150px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-70px, -40px);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
    }

    &::after {
      content: '';
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(10px, 50px);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
    }
  }

  &__actions {
    @include wxga() {
      display: grid;
      grid: 1fr / 2fr 1fr;
      gap: 0 90px;
    }
  }

  &__button {
    margin-left: auto;
    border: none;
    cursor: pointer;
    background-color: $primary-color;
    border-radius: 6px;
    padding: 15px 35px;
    font-weight: 500;
    font-size: 1.125rem;
    color: $primary-btn-text-color;

    &--refund {
      display: none;

      @include wxga() {
        display: block;
      }
    }
  }

  &__no-data {
    color: $description-color;
    font-size: 1rem;
    margin: 20px;
  }
}

.card-visualization {
  display: grid;
  grid: 1fr / auto 1fr;
  gap: 0 10px;

  &__logo {
    padding: 10px 0 0 10px;
    width: 50px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1rem;
    color: $secondary-text-color;
    padding: 20px 40px 20px 20px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__number {
    margin-bottom: 30px;
    white-space: pre-wrap;

    @include xga() {
      margin-bottom: 40px;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
    flex-wrap: wrap;

    @include xga() {
      margin-bottom: 10px;
    }
  }
}

.mastercard-logo-text {
  fill: white;
}
