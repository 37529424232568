@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.filters-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 9px 8px;
  margin-top: 15px;

  &__badge {
    padding: 0 7px;
    border-radius: 2px;
    background-color: $primary-light-grey;
    color: $primary-color;
    font-weight: 300;
    font-size: 1rem;

    i {
      color: $secondary-light-grey;
      font-size: 0.625rem;
      margin-right: 7px;
      cursor: pointer;
    }
  }
}
