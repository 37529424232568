@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.recruiter-job-tile {
  .job-tile {
    width: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.4s all;
    align-items: flex-start;
    padding: 16px 10px 2px 16px;

    background-color: $secondary-background-color;
    border: 1px solid $secondary-background-color;

    &--active {
      border-color: $primary-color;
    }

    &__block {
      width: max-content;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &--middle {
        flex-grow: 1;
        padding: 0 16px;
      }
    }

    &__logo {
      width: 40px;
      height: 40px;
      display: flex;
      overflow: hidden;
      border-radius: 6px;
      align-items: center;
      justify-content: center;

      border: 1px solid $border-default-color;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &:empty {
        position: relative;

        background-color: $default-logo-background-color;

        &::after {
          top: 50%;
          left: 50%;
          width: 6px;
          content: '';
          height: 30px;
          position: absolute;
          transform: translate(-50%, -50%) skew(20deg);

          background-color: $primary-black;
        }
      }
    }

    &__title {
      font-weight: 500;
    }

    &__company {
      padding: 2px 0 0;

      color: $description-color;
    }

    &__status {
      font-weight: 500;
      text-align: right;
      text-transform: capitalize;

      color: $status-color-active;

      &--draft {
        color: $status-color-draft;
      }

      &--paused {
        color: $status-color-paused;
      }

      &--position_filled {
        color: $status-color-filled;
      }

      &--withdrawn {
        color: $status-color-withdrawn;
      }
    }

    &__date {
      text-align: right;
      padding: 14px 0 0;

      color: $description-color;
    }
  }
}
