@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.complete-registration {
  width: 332px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;

  @include xga {
    width: 432px;
  }

  &__header {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 128px 0 8px;
    line-height: 2.0625rem;

    color: $primary-title-color;

    @include xga() {
      font-size: 1.75rem;
      padding: 74px 0 14px;
      line-height: 2.375rem;
    }
  }

  &__description {
    font-weight: 300;
    padding: 0 0 68px;
    text-align: center;
    line-height: 1.375rem;

    color: $primary-description-color;

    @include xga {
      padding: 0 0 118px;
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }

  &__form {
    width: 100%;
  }

  &__field {
    width: 100%;
    margin: 0 0 24px;
    position: relative;

    &--with-icon {
      button {
        top: 0;
        padding: 0;
        right: 16px;
        height: 100%;
        border: none;
        display: block;
        position: absolute;
        background-color: rgba(0, 0, 0, 0);

        i {
          cursor: pointer;
          font-size: 1.625rem;
          transition: 0.2s color;
          color: $secondary-inactive-color;

          &:hover {
            color: $primary-lila;
          }
        }
      }

      .complete-registration__input {
        padding: 19px 56px 19px 14px;

        @include xga {
          padding: 0 60px 0 18px;
        }
      }
    }

    &--with-icon-active {
      button i {
        color: $primary-color;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &--last {
      margin: 0;
    }
  }

  &__input {
    width: 100%;
    border: none;
    font-weight: 300;
    border-radius: 8px;
    padding: 19px 14px;
    font-size: 1.125rem;
    line-height: 1.5625rem;

    color: $primary-text-color;
    background-color: $primary-btn-text-color;

    @include xga() {
      height: 76px;
      display: flex;
      padding: 0 18px;
      font-size: 1.125rem;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    font-weight: 300;
    padding: 206px 0 0;
    align-items: center;
    line-height: 1.375rem;
    flex-direction: column;

    @include xga() {
      padding: 178px 0 0;
      font-size: 1.125rem;
      line-height: 1.5625rem;
    }
  }
}
