@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.billing-contact {
  display: flex;
  padding: 22px 2px;
  border-bottom: 1px solid $border-default-color;
  align-items: center;
  gap: 0 9px;

  &__avatar {
    width: 40px;
    height: 40px;
    background-color: $primary-color;
    color: $secondary-text-color;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 1.125rem;
    color: $default-label-color;
  }

  &__email {
    font-weight: 300;
    font-size: 1rem;
    color: $primary-description-color;
  }

  &__delete {
    border: none;
    background-color: $secondary-background-color;
    font-size: 1.188rem;
    cursor: pointer;
    margin-left: auto;
  }
}
