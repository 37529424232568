@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-search-results {
  &__header {
    font-weight: 300;
    font-size: 1rem;
    color: $default-label-color;
    margin-bottom: 14px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    @include tablet() {
      grid-template-columns: 1fr 1fr;
      justify-items: flex-start;
    }

    @include wxga() {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include wxgaplus() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
