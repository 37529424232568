@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.reviews {
  padding-bottom: 17px;

  @include wxga() {
    grid-row: 5/6;
    grid-column: 2/3;
    padding: 0 46px;
    margin-bottom: 37px;
    border-left: 1px solid $border-default-color;
  }

  &__title {
    color: $primary-text-color;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 45px 0 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }

  &__card {
    display: grid;
    grid: repeat(3, 0fr) / 0fr 1fr;
    gap: 4px 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 16px 20px;
  }

  &__avatar {
    width: 64px;
    height: 64px;
    display: flex;
    grid-row: 1/4;
    grid-column: 1/2;
    overflow: hidden;
    font-size: 1.5rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__value {
    grid-row: 1/2;
    grid-column: 2/3;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__recruiter {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 0.875rem;
  }

  &__rating {
    grid-row: 3/4;
    grid-column: 2/3;

    .rating-star {
      font-size: 0.875rem;

      &--empty {
        color: $tertiary-inactive-color;
      }

      &--filled {
        color: $primary-color;
      }
    }
  }
}
