@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.rating-header {
  display: grid;
  grid: repeat(3, 0fr) / 0fr 1fr;
  gap: 7px 8px;

  @include wxga() {
    grid-row: 2/3;
    grid-column: 1/3;
  }

  &__avatar {
    width: 122px;
    height: 92px;
    display: flex;
    grid-row: 1/3;
    overflow: hidden;
    font-size: 1.5rem;
    border-radius: 4px;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 1.75rem;
    color: $default-label-color;
  }

  &__stars {
    .rating-star {
      font-size: 1.313rem;

      &--empty {
        color: $tertiary-inactive-color;
      }

      &--filled {
        color: $primary-color;
      }
    }
  }
}
