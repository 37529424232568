@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-step {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include xga() {
      padding: 0 15px 12px 0;
      justify-content: flex-end;
    }

    &--publish {
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;

      @include xga() {
        align-items: initial;
        justify-content: flex-end;
        padding: 0 14px;
      }
    }
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin: 0 0 0 10px;
    border-radius: 6px;
    padding: 15px 35px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    &--secondary {
      color: $primary-color;
      background-color: $primary-light-grey;
    }

    &:disabled {
      background-color: #ddd;
      cursor: default;
    }
  }
}
