@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.settings-employer {
  display: flex;
  flex-direction: column;

  &__actions {
    display: flex;
    justify-content: flex-end;
    grid-column-start: 2;
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 6px;
    padding: 15px 35px;
    margin: 18px 0 26px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    @include xga {
      padding: 10px 20px;
      font-size: 0.875rem;
      margin: 26px 0 46px;
      line-height: 1.25rem;
    }
  }

  &__columns {
    display: block;

    @include xga {
      display: grid;
      column-gap: 18px;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__card {
    margin: 0 0 16px;
  }
}
