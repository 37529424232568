@import './Colors.scss';

.landing-footer,
.benefits-footer {
  padding: 20px;
  background: #004e66;
}

.landing-text {
  white-space: pre-wrap;
}

.footer-nav {
  margin-bottom: 20px;
}

.footer-nav > a {
  color: white;
  font-weight: normal;
}

.footer-nav > a:hover {
  border-bottom: 1px solid white;
  color: white;
}

.footer-logo {
  position: relative;
  right: 15px;
  bottom: 10px;
}

.footer-about {
  font-weight: normal;
}

.img-sponsors {
  padding: 10px 0px;
  width: 150px;
}

.is-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.benefits-footer {
  padding-top: 50px;
  min-height: 200px;
}

.benefits-block {
  height: 250px;
}

.benefits-full-line,
.benefits-half-line {
  height: 10px;
  margin: 10px auto;
  border-radius: 5px;
}

.benefits-half-line {
  width: 20%;
  opacity: 0.5;
}

.benefits-full-line {
  width: 60%;
}

.benefits-text-block {
  min-height: 130px;
}

.benefits-figures {
  height: 150px;
}

.benefits-content {
  max-width: 350px;
  margin: 0px auto;
}
