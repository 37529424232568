@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job {
  display: flex;
  flex-direction: column;

  @include xga() {
    flex-direction: row;
  }

  &__steps {
    width: 100%;
  }
}

.new-job-step {
  padding: 30px 0 30px;
  background-color: $secondary-background-color;
  width: 100%;

  @include xga() {
    padding-bottom: 0px;
  }

  &__header {
    padding: 0 13px 0;

    @include xga() {
      padding: 0 40px 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 25px;
    color: $primary-title-color;

    @include xga() {
      font-size: 1.75rem;
      margin: 0 0 8px;
    }
  }

  &__description {
    font-weight: normal;
    font-size: 1rem;
    line-height: 22px;
    color: $secondary-description-color;
  }

  &__label {
    font-weight: 300;
    font-size: 0.875rem;
    color: $secondary-label-color;

    @include xga() {
      font-weight: normal;
      font-size: 1rem;
      color: $default-label-color;
    }

    sup {
      color: red;
    }
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    @include xga() {
      margin-bottom: 20px;
    }

    textarea {
      border: 1px solid $border-default-color;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 9px 15px;
      resize: vertical;
    }
  }

  &__actions {
    span {
      align-self: end;
    }

    a {
      color: $primary-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
