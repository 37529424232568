@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-description {
  $root: '.job-description';

  display: flex;
  flex-direction: column;
  padding-top: 32px;

  @include xga() {
    max-width: 790px;
    padding: 32px 0 39px;
  }

  &__label {
    font-size: 0.875rem;
    color: $primary-description-color;
    margin-bottom: 6px;
  }

  &__title {
    margin: 24px 0 22px;
    padding: 0 13px;
  }

  &__header {
    font-weight: 500;
    font-size: 1.5px;
    color: $default-label-color;
  }

  &__details {
    display: flex;
    flex-direction: column;
    background-color: $default-background-color;
    padding: 13px 10px;
    border-radius: 4px;
    margin: 0 13px 46px;

    @include xga() {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: 0fr 0fr;
      gap: 12px 48px;
      width: fit-content;
      background-color: $secondary-background-color;
      margin: 0 0 26px;
      order: 0;
      padding-left: 0;
    }
  }

  &__explanation {
    padding: 22px 12px 42px;

    border-top: 1px solid $border-default-color;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      order: 1;
      padding-left: 0;
    }

    #{$root}__label {
      margin: 12px 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }

  &__commission {
    padding: 22px 13px 0;
    margin-bottom: 22px;

    @include xga() {
      order: 4;
      border-top: 1px solid $border-default-color;
      margin-bottom: 26px;
      padding-left: 0;
    }
  }

  &__additional-info {
    margin-bottom: 41px;
    padding: 0 13px;

    @include xga() {
      order: 5;
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  &__text {
    font-size: 0.875rem;
    line-height: 26px;
    color: $tertiary-text-color;

    ul {
      list-style: disc;
    }
  }

  &__skills {
    @include xga() {
      order: 2;
      display: flex;
      margin: 22px 0 38px;
    }
  }

  &__languages {
    padding: 22px 16px;
    border-top: 1px solid $border-default-color;

    @include xga() {
      border-top: none;
      padding: 0;
      order: 3;
    }
  }

  &__badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 9px 16px;
  }

  &__badge {
    padding: 0 16px;
    background-color: $primary-light-grey;
    color: $primary-color;
    border-radius: 2px;
    font-weight: 300;
  }

  &__language {
    display: flex;
    background-color: $primary-light-grey;
    width: fit-content;
  }

  .details {
    font-size: 0.875rem;

    &__label {
      color: $secondary-description-color;
    }

    &__value {
      font-size: 1rem;
      color: $primary-color;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      @include xga() {
        justify-content: flex-start;
        margin-bottom: 0;
        gap: 0 10px;
        align-items: flex-end;
      }
    }
  }

  .commission {
    &__value {
      font-weight: 500;
      font-size: 1.125rem;
      color: $primary-color;
    }

    &__warning {
      color: $primary-red;
    }
  }

  .skills {
    @include xga() {
      gap: 0 50px;
    }

    &__container {
      padding: 22px 16px;
      border-top: 1px solid $border-default-color;

      @include xga() {
        border-top: none;
        padding: 0;
      }
    }

    &__label {
      font-size: 1.125rem;
      color: $default-label-color;
      margin-bottom: 18px;
    }
  }

  .language {
    &__name {
      font-size: 1rem;
      color: $primary-color;
      font-weight: 300;
      padding: 5px 34px 5px 9px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &__level {
      font-size: 1rem;
      color: $secondary-text-color;
      background-color: $primary-color;
      padding: 6px 12px;
      border-radius: 2px;
    }
  }

  .languages {
    &__container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 85px;
      gap: 10px 16px;

      @include xga() {
        margin-bottom: 38px;
      }
    }
  }
}
