@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.feed-card-header {
  $root: '.feed-card-header';

  display: flex;
  padding: 16px;
  gap: 0 16px;
  background-color: $primary-card-background;
  align-items: center;

  @include xga() {
    padding: 12px 14px 12px 21px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &--action {
    #{$root}__icon,
    #{$root}__title {
      cursor: pointer;
    }
  }

  &__icon {
    font-size: 1rem;
    color: $default-label-color;
  }

  &__title {
    font-weight: 500;
    font-size: 1.375rem;
    color: $default-label-color;

    @include xga() {
      font-size: 1rem;
    }
  }

  &__action {
    margin-left: auto;
    font-size: 1rem;
    color: $primary-color;
    border: none;
    background-color: $primary-card-background;
    cursor: pointer;

    i {
      display: none;
    }

    @include xga() {
      i {
        display: initial;
        font-size: 0.875rem;
        color: $default-label-color;
      }

      span {
        display: none;
      }
    }
  }

  &--your-benefits {
    background-color: $secondary-color;
    padding: 12px 14px 12px 21px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .feed-card-header__title {
      font-weight: 500;
      font-size: 1rem;
      color: $secondary-text-color;
    }

    .feed-card-header__action {
      display: none;
    }

    .feed-card-header__icon {
      font-size: 0.875rem;
      color: $secondary-text-color;
    }
  }
}
