@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.recruiter-profile {
  background-color: $secondary-background-color;

  @include xga {
    padding: 18px 24px;
    border-radius: 6px;
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
  }

  &__head {
    @include xga {
      display: grid;
      gap: 34px 20px;
      align-items: flex-start;
      grid-template-columns: 600px 1fr;
      grid-template-rows: max-content 1fr;
    }
  }

  &__image {
    display: flex;
    grid-row: 1/3;
    min-height: 260px;
    position: relative;
    height: max-content;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border-default-color;

    @include xga {
      width: 100%;
      height: 420px;
      overflow: hidden;
      border-radius: 16px;

      &--default {
        border: 1px solid $border-default-color;
      }
    }

    img {
      width: 100%;
    }

    > i {
      opacity: 0.26;
      font-size: 5.125rem;

      color: $primary-link-color;
    }
  }

  &__rate {
    gap: 0 10px;
    right: 16px;
    bottom: 12px;
    display: flex;
    position: absolute;
  }

  &__basic {
    position: relative;
    padding: 12px 116px 32px 16px;

    @include xga {
      padding: 0 100px 0 0;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 2.125rem;
    line-height: 3.25rem;

    color: $default-label-color;

    @include xga {
      font-size: 1.75rem;
      line-height: 2.125rem;
    }
  }

  &__base {
    line-height: 1.125rem;

    color: $description-color;

    span {
      color: $default-label-color;
    }

    @include xga {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  &__actions {
    top: 26px;
    gap: 0 8px;
    right: 16px;
    display: flex;
    position: absolute;

    @include xga {
      top: 0;
      right: 0;
    }
  }

  &__about {
    margin: 0 16px;
    padding: 0 0 52px;
    line-height: 1.375rem;

    color: $description-color;

    border-bottom: 1px solid $border-default-color;

    @include xga {
      display: none;
    }

    &--desktop-only {
      display: none;

      @include xga {
        display: block;
        margin: 0 0 22px;
        font-size: 0.875rem;
        padding: 16px 0 10px;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column-reverse;

    @include xga {
      grid-row: 2/3;
      display: block;
      flex-direction: unset;
    }
  }

  &__links {
    padding: 20px 16px 42px;

    color: $default-label-color;

    @include xga {
      padding: 0;
    }

    h3 {
      font-weight: 500;
      line-height: 1.125rem;

      @include xga {
        display: none;
      }
    }
  }

  &__communication {
    border-top: 1px solid $border-default-color;

    @include xga {
      border: none;
    }
  }

  &__socials {
    gap: 6px 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 0;

    @include xga {
      padding: 20px 0 0;
    }
  }

  &__social {
    width: max-content;

    a {
      border: none;
      display: flex;
      cursor: pointer;
      font-weight: 500;
      border-radius: 6px;
      padding: 12px 14px;
      width: max-content;
      align-items: center;
      line-height: 1.125rem;
      justify-content: center;

      color: $primary-color;
      background-color: $secondary-btn-color;
    }

    i {
      display: none;
      margin: 0 8px 0 0;
    }

    &--linkedin {
      a {
        color: $button-color-linkedin;
        background-color: $button-background-linkedin;

        i {
          display: block;

          color: $button-color-linkedin;

          &::before {
            content: '\e92f';
          }
        }
      }
    }

    &--twitter {
      a {
        color: $button-color-twitter;
        background-color: $button-background-twitter;

        i {
          display: block;
          font-size: 0.875rem;

          color: $button-color-twitter;

          &::before {
            content: '\e92d';
          }
        }
      }
    }

    &--xing {
      a {
        color: $button-color-xing;
        background-color: $button-background-xing;

        i {
          display: block;

          color: $button-color-xing;

          &::before {
            content: '\e92e';
          }
        }
      }
    }
  }

  &__content {
    @include xga {
      max-width: 638px;
      padding: 32px 0 0;
    }
  }

  &__button {
    gap: 0 6px;
    width: 42px;
    height: 42px;
    border: none;
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 6px;
    align-items: center;
    justify-content: center;

    color: $secondary-text-color;
    background-color: $primary-color;

    &--secondary {
      color: $primary-color;
      background-color: $default-tile-color;
    }

    &--tertiary {
      color: $primary-text-red;
      background-color: $primary-light-red;
    }

    &--quaternary {
      color: $default-label-color;
      background-color: $secondary-background-color;

      i {
        color: $primary-rating-color;
      }
    }

    &--text {
      width: auto;
      padding: 0 16px;
    }

    i {
      margin: 0 6px 0 0;

      &:only-child {
        margin: 0;
      }
    }
  }

  &__title {
    display: flex;
    font-weight: 500;
    align-items: center;
    font-size: 1.375rem;
    line-height: 1.75rem;
    padding: 20px 16px 6px;

    color: $default-label-color;

    &--with-icon {
      height: 40px;
    }

    &--desktop-only {
      display: none;

      @include xga {
        display: flex;
      }
    }

    @include xga {
      padding: 0;
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  &__rows {
    padding: 0 8px;

    @include xga {
      padding: 12px 0 0;
    }
  }

  &__row.row {
    $row: 'row';

    display: flex;
    padding: 16px 8px;
    line-height: 1.125rem;
    justify-content: space-between;
    border-bottom: 1px solid $border-default-color;

    @include xga {
      padding: 10px 0;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }

    .#{$row}__label {
      color: $description-color;

      @include xga {
        padding: 0 24px 0 0;
      }
    }

    .#{$row}__value {
      max-width: 162px;
      text-align: right;
      word-wrap: break-word;

      color: $default-label-color;

      @include xga {
        max-width: 256px;
      }
    }

    &:last-child {
      border: none;
    }
  }

  &__block {
    display: flex;
    padding: 32px 8px 16px 0;
    justify-content: flex-end;

    @include xga {
      padding: 32px 0 16px;
    }
  }
}
