@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.feed-tile {
  display: flex;
  padding: 17px 10px;
  background: $primary-card-background;
  border-radius: 8px;
  gap: 0 14px;
  align-items: center;
  cursor: pointer;

  &__photo {
    width: 40px;
    height: 40px;

    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  &--jobs {
    .feed-tile__photo {
      border-radius: 6px;

      border: 1px solid $border-default-color;

      .feed-tile__no-photo {
        border-radius: 6px;
        position: relative;

        color: $default-logo-background-color;
        background-color: $default-logo-background-color;

        &::after {
          top: 50%;
          left: 50%;
          width: 6px;
          content: '';
          height: 30px;
          position: absolute;
          transform: translate(-50%, -50%) skew(20deg);

          background-color: $primary-black;
        }
      }
    }
  }

  &--unread {
    background-color: $unread-message-tile-color;
  }

  &__no-photo {
    width: 40px;
    height: 40px;
    background-color: $primary-color;
    color: $secondary-text-color;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    overflow: hidden;
  }

  &__name {
    font-weight: 500;
    font-size: 0.875rem;
    color: $default-label-color;
  }

  &__description {
    font-size: 0.875rem;
    color: $description-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
    margin-left: auto;
    font-size: 0.75rem;
    color: $description-color;
  }
}
