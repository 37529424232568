@import './variables.scss';

.spinner-container {
  margin: auto;
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner {
  animation: spin 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: $spinner-1-color;
  border-top-color: $spinner-1-color;
  box-sizing: border-box;
  height: 20vmin;
  margin: 0px auto;
  width: 20vmin;
  z-index: 1;
  &:before {
    animation: spin 2s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: $spinner-2-color;
    border-top-color: $spinner-2-color;
    box-sizing: border-box;
    content: '';
    height: 16vmin;
    left: 0;
    position: absolute;
    top: 0;
    width: 16vmin;
  }
  &:after {
    animation: spin 3s infinite linear;
    border: solid 2vmin transparent;
    border-radius: 50%;
    border-right-color: $spinner-3-color;
    border-top-color: $spinner-3-color;
    box-sizing: border-box;
    content: '';
    height: 12vmin;
    left: 2vmin;
    position: absolute;
    top: 2vmin;
    width: 12vmin;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
