@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.messaging-tile {
  display: grid;
  grid: 1fr 1fr / auto 1fr;
  gap: 0 8px;
  border: 1px solid $transparent-color;
  border-bottom: 1px solid $border-default-color;
  padding: 17px 8px;

  &:last-child:not(&--selected) {
    border-bottom: 1px solid $transparent-color;
  }

  &--selected {
    border-radius: 8px;
    border: 1px solid $primary-color;
  }

  .messaging-avatar {
    grid-row: 1/3;
    grid-column: 1/2;
  }

  &__name {
    grid-row: 1/2;
    grid-column: 2/3;
    color: $primary-text-color;
    font-weight: 500;
    font-size: 0.875rem;

    &--has-new-messages {
    }
  }

  &__last-message {
    grid-row: 2/3;
    grid-column: 2/3;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    color: $secondary-description-color;

    &--has-new-messages {
      font-weight: 500;
      color: $primary-text-color;
    }
  }

  &--has-new-messages {
    background-color: $unread-message-tile-color;
    border-radius: 6px;
  }
}
