@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.hr-job {
  .job {
    background-color: $secondary-background-color;

    @include xga() {
      box-shadow: 0 6px 18px rgba(62, 56, 106, 0.06);
      border-radius: 6px;
    }

    &__navigation {
      display: flex;
      justify-content: space-between;
      padding: 11px 10px 0 24px;
      position: relative;
    }

    &__tabs {
      .base-tabs-routes__content {
        padding: 0;

        @include xga {
          padding: 0 38px 16px 42px;
        }
      }
    }

    &__page-name {
      justify-self: center;
      color: $default-label-color;
      font-weight: 500;
      font-size: 1rem;
      display: flex;
      align-items: center;

      @include xga() {
        display: none;
      }
    }

    &__menu {
      i {
        color: $primary-black;
        font-size: 2rem;
      }
      @include wxga() {
        display: none;
      }
    }

    &__header {
      margin: 22px 0 35px;
      padding: 0 16px;

      @include xga() {
        display: flex;
        margin: 25px 0 40px;

        padding: 0 23px 0 24px;
      }
    }

    &__head {
      display: flex;
      justify-content: center;

      @include xga() {
        justify-content: flex-start;
      }
    }

    &__statistics {
      @include xga {
        margin-left: auto;
      }
    }

    &__tail {
      display: flex;
      margin-left: auto;
      justify-content: space-between;
    }

    &__status {
      display: none;
      text-transform: capitalize;

      @include xga() {
        display: initial;
        margin: 10px 0 10px 30px;
        background-color: $status-open-color;
        border-radius: 4px;
        padding: 1px 11px;
        color: $secondary-text-color;
        height: fit-content;
      }

      &--draft {
        background-color: $label-background-color-draft;
      }

      &--paused {
        background-color: $label-background-color-paused;
      }

      &--position_filled {
        background-color: $label-background-color-filled;
      }

      &--withdrawn {
        background-color: $label-background-color-withdrawn;
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 20px;

      @include xga() {
        margin: 0;
        text-align: start;
      }
    }

    &__actions {
      display: none;

      @include wxga() {
        display: flex;
        column-gap: 6px;
        justify-self: flex-end;
        align-items: flex-start;
      }
    }

    &__basic-info {
      display: flex;
      justify-content: center;

      @include xga() {
        justify-content: flex-start;
      }
    }

    &__position {
      font-weight: 500;
      font-size: 2.125rem;
      color: $default-label-color;

      @include xga() {
        max-width: 400px;
        font-size: 1.75rem;
      }
    }

    &__location,
    &__company {
      color: $primary-title-color;
      font-size: 1rem;
    }

    &__location {
      padding-right: 9px;
      border-right: 1px solid $default-label-color;
    }

    &__company {
      margin-left: 9px;
    }

    &__button {
      width: 44px;
      height: 42px;
      border: none;
      display: flex;
      cursor: pointer;
      border-radius: 6px;
      font-size: 1.125rem;
      align-items: center;
      justify-content: center;

      &--edit {
        background-color: $default-tile-color;
        color: $primary-color;
      }

      &--pause {
        background-color: $primary-light-blue;
        color: $primary-text-blue;
      }

      &--archive {
        background-color: $primary-light-red;
        color: $primary-text-red;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__vertical-menu {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: $secondary-background-color;
      display: none;
      border: 1px solid $border-default-color;

      &--active {
        display: flex;
        flex-direction: column;

        @include wxga() {
          display: none;
        }
      }
    }

    &__menu-button {
      padding: 5px 10px;
      border: none;
      border-bottom: 1px solid $border-default-color;
      background-color: $secondary-background-color;
      font-size: 1rem;
      cursor: pointer;
      text-align: start;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .job-tabs {
    &__navigation {
      width: initial;

      @include xga() {
        gap: 0 76px;
        padding: 0 40px;
        max-width: 750px;
      }

      & > .base-tab {
        font-weight: normal;

        @include xga() {
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    &__content {
      @include xga {
        padding: 0 38px;
      }
    }
  }
}
