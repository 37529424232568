@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$switch: 'switch';

.settings-communication {
  background-color: $primary-card-background;

  @include xga() {
    padding-left: 32px;
    padding-right: 233px;
  }

  &__header {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 22px 0 8px;

    color: $primary-color;
  }

  &__consent {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 30px 8px 30px 14px;
    justify-content: space-between;

    color: $default-label-color;
    border-bottom: 1px solid $border-default-color;

    @include xga() {
      font-size: 1.125rem;
    }

    &:nth-last-child(2) {
      border: none;
    }

    &:first-child {
      padding: 22px 8px 30px 14px;
    }
  }

  &__switch.#{$switch} {
    width: 61px;
    height: 29px;
    position: relative;
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .#{$switch}__input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .#{$switch}__slider {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.3s;
      cursor: pointer;
      overflow: hidden;
      position: absolute;
      border-radius: 38px;
      box-shadow: inset -4px 0px 18px -12px #888;

      background-color: $primary-light-grey;

      &:before {
        left: 3px;
        z-index: 2;
        content: '';
        bottom: 3px;
        width: 23px;
        height: 23px;
        transition: 0.6s;
        border-radius: 50%;
        position: absolute;

        background-color: $primary-color;
      }
    }

    .#{$switch}__input:checked + .#{$switch}__slider {
      background-color: $primary-color;
    }

    .#{$switch}__input:checked + .#{$switch}__slider:before {
      transform: translateX(31px);

      background-color: $secondary-background-color;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin: 56px 0 0;
    border-radius: 6px;
    padding: 15px 35px;
    line-height: 1.5rem;
    font-size: 1.125rem;

    color: $primary-btn-text-color;
    background-color: $primary-color;

    @include xga {
      margin: 26px 0 0;
      padding: 10px 20px;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
