@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.job-process-card {
  background-color: $secondary-background-color;
  border-radius: 16px;
  width: 100%;
  padding: 8px 11px 6px 20px;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;

  @include wxga() {
    padding: 7px 5px 6px 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    color: $primary-title-color;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 10px;

    @include wxga() {
      word-spacing: 100vw;
    }
  }

  &__like {
    color: $primary-blue;
    font-size: 0.813rem;

    i {
      cursor: pointer;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__recruiter {
    order: 1;
    color: $primary-color;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 14px;

    @include wxga() {
      margin-bottom: 7px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @include wxga() {
      flex-direction: column;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.date {
  &__label {
    color: $primary-text-green;
    font-weight: 500;
    font-size: 0.875rem;

    @include wxga() {
      order: 2;
      align-self: flex-end;

      & ~ .date__diff {
        margin-bottom: 0;
      }
    }
  }

  &__diff {
    width: fit-content;
    align-self: flex-end;
    color: $secondary-description-color;
    font-size: 0.75rem;

    @include wxga() {
      order: 1;
      align-self: flex-start;
      margin-bottom: 21px;
    }
  }
}

.counter {
  order: 2;
  display: flex;

  & ~ .job-process-card__recruiter {
    margin-bottom: 10px;
  }

  @include wxga() {
    justify-content: space-between;
  }

  &__label {
    font-size: 0.875rem;
    color: $primary-yellow-text-color;
    margin-right: 14px;
  }

  &__field {
    @include wxga() {
      display: flex;
      align-items: center;
    }

    input {
      font-size: 0.875rem;
      width: 31px;
      height: 25px;
      border-radius: 1px;
      border: 0.5px solid $border-default-color;
      color: $primary-yellow-text-color;
    }
  }

  &--started {
    flex-direction: column;
    justify-content: flex-start;

    input {
      width: 100%;
    }
  }

  @include wxga() {
    margin: 0 0 10px;
  }
}
