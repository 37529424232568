@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-additional-info {
  padding: 0 13px;

  @include xga() {
    padding: 0 24px 0 39px;
  }

  &__container {
    margin: 39px 0 155px;

    @include xga() {
      margin: 60px 0 245px;
    }
  }
}
