@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.feed {
  display: grid;
  flex-direction: column;
  margin-bottom: 68px;
  grid-template-columns: minmax(0, 1fr);

  @include xga() {
    display: grid;
    grid-template-rows: 324px 324px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 26px;
    margin-bottom: 0;
  }
}
