@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.add-review {
  display: flex;
  flex-direction: column;
  margin-top: 47px;

  @include wxga() {
    grid-row: 4/5;
    grid-column: 1/2;
    padding-right: 53px;
  }

  &__button {
    font-family: 'Halyard Display', sans-serif;
    padding: 15px 35px;
    border: none;
    font-weight: 500;
    font-size: 1.125rem;
    color: $primary-btn-text-color;
    background-color: $primary-color;
    border-radius: 6px;
    align-self: flex-end;
    cursor: pointer;
  }

  .base-text-area__input {
    min-height: 121px;
  }
}
