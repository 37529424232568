@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.talent-application {
  .application {
    width: 100%;
    border-radius: 8px;

    background-color: $primary-card-background;
    box-shadow: 0 4px 12px $border-default-color;

    @include xga {
      transition: 0.4s all;
      border: 1px solid $primary-card-background;

      &--active {
        border: 1px solid $primary-color;
      }
    }

    &__container {
      display: flex;
      padding: 14px 11px 8px 8px;

      @include xga {
        padding: 16px 14px 10px 16px;
      }
    }

    &__logo {
      width: 40px;
      margin: 4px 8px 0 0;
    }

    &__image {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 4px;

      border: 1px solid $border-default-color;

      img {
        height: 100%;
        object-fit: cover;
      }

      &:empty {
        position: relative;

        background-color: $default-logo-background-color;

        &::after {
          top: 50%;
          left: 50%;
          width: 6px;
          content: '';
          height: 30px;
          position: absolute;
          transform: translate(-50%, -50%) skew(20deg);

          background-color: $primary-black;
        }
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
    }

    &__right {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      gap: 13px 0;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__position {
      font-weight: 500;
      padding: 0 0 4px;
      font-size: 0.875rem;
      color: $primary-title-color;
    }

    &__company,
    &__date {
      line-height: 1;
      font-size: 0.875rem;
      color: $description-color;
    }

    &__status {
      display: flex;
      padding: 10px 0 0;
    }

    &__job-status {
      font-weight: 500;
      font-size: 0.875rem;
      text-transform: capitalize;

      color: $status-color-active;

      &--draft {
        color: $status-color-draft;
      }

      &--paused {
        color: $status-color-paused;
      }

      &--position_filled {
        color: $status-color-filled;
      }

      &--withdrawn {
        color: $status-color-withdrawn;
      }
    }

    &__reject {
      height: 32px;
      border: none;
      display: flex;
      padding: 0 8px;
      cursor: pointer;
      margin: 0 0 0 4px;
      border-radius: 6px;
      font-size: 0.625rem;
      align-items: center;
      justify-content: center;

      color: $primary-text-red;
      background-color: $primary-light-red;

      i {
        width: 16px;
      }
    }

    &__button {
      gap: 0 6px;
      border: none;
      display: flex;
      line-height: 1;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      color: $primary-description-color;
      background-color: rgba(0, 0, 0, 0);
    }

    &__details {
      text-align: right;
      padding: 14px 6px 14px;
    }

    &__detail {
      gap: 0 20px;
      display: flex;
      min-height: 60px;
      padding: 14px 0 0;
      font-size: 0.875rem;
      line-height: 1.125rem;
      justify-content: space-between;

      color: $secondary-description-color;
      border-top: 1px solid $border-default-color;

      @include xga {
        padding: 14px 8px 0;
      }
    }

    &__label {
      flex-shrink: 0;
    }

    &__value {
      text-align: right;
    }

    &__edit {
      border: none;
      height: 32px;
      display: flex;
      padding: 0 16px;
      font-size: 1rem;
      cursor: pointer;
      margin: 0 0 0 auto;
      width: max-content;
      border-radius: 6px;
      align-items: center;
      line-height: 1.125rem;
      justify-content: center;

      color: $primary-color;
      background-color: $default-tile-color;
    }
  }
}
