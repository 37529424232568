@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.auth {
  min-height: 100vh;
  background-size: cover;
  background-image: url('../../assets/background-lines-mobile.svg');

  @include xga {
    background-image: url('../../assets/background-lines.svg');
  }

  .auth-head {
    display: none;

    @include xga {
      display: block;
    }

    &__title {
      font-weight: 500;
      font-size: 1.5rem;
      padding: 12px 0 0 26px;

      color: $primary-black;

      span {
        font-weight: 300;
      }
    }
  }
}
