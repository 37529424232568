@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$root: 'base-select';

.base-select {
  &__label {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 19px;
    color: $secondary-label-color;
    margin-left: 5px;

    sup {
      color: red;
    }

    &--selected {
      opacity: 1;
    }
  }

  &--new-job > #{&}__label {
    @include xga() {
      font-size: 1rem;
      color: $default-label-color;
      font-weight: normal;
    }
  }

  &__message {
    height: 20px;
    font-weight: 300;
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.25rem;

    color: $secondary-label-color;

    &--error {
      color: $primary-red;
    }
  }

  .#{$root}__menu {
    z-index: 3;
    transform: translateY(-1px);
  }

  .#{$root}__control--menu-is-open {
    border-bottom: 1px solid $primary-color;
  }

  .#{$root}__value-container {
    font-weight: 300;
    padding: 2px 16px;

    .#{$root}__single-value,
    .#{$root}__placeholder {
      @include xga {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
  }

  .#{$root}__value-container .#{$root}__multi-value {
    background-color: rgba(0, 0, 0, 0);
    border-right: 0.5px solid $border-tertiary-color;

    .#{$root}__multi-value__label {
      font-size: 1rem;

      @include xga {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    .#{$root}__multi-value__remove {
      display: flex;
      padding: 0 8px;
      cursor: pointer;
      font-size: 0.625rem;
      align-items: center;
      transition: 0.2s color;

      &:hover {
        color: $primary-red;
      }
    }
  }
}
