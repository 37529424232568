@import 'src/ui/scss/Colors';

.message-field-file {
  display: flex;
  flex-direction: row;
  background-color: $default-background-color;
  border-radius: 5px;
  padding: 6px 10px;
  margin-bottom: 16px;
  font-size: 14px;

  &__name {
    flex-grow: 1;

    span {
      color: $tertiary-description-color;
    }
  }

  &__delete {
    cursor: pointer;
    border: none;
    background-color: $transparent-color;
    font-size: 9px;
    color: $tertiary-description-color;
  }

  &__spinner {
    display: inline-block;
    width: 20px;
    height: 20px;

    &:after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid;
      border-color: $primary-color $transparent-color $primary-color $transparent-color;
      animation: dual-ring-spinner 1.2s linear infinite;
    }

    @keyframes dual-ring-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
