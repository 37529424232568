@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-step-publish {
  @include xga() {
    margin-top: 36px;
  }

  &__title {
    padding: 0 19px 0 13px;
    margin: 24px 0 18px;

    @include xga() {
      display: none;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 26px;
    color: $primary-description-color;
  }

  &__header {
    font-weight: 500;
    font-size: 24px;
    color: $default-label-color;
  }
}
