@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.talent-benefits {
  display: flex;
  flex-direction: column;
  padding: 42px 13px 71px;
  max-width: 522px;

  @include wxga() {
    padding: 34px 11px 182px;
  }
}
