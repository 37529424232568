@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.new-job-step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 19px;
    left: 50%;
    width: 100%;
    height: 1px;
    background: $primary-color;

    @include xga() {
      display: none;
    }
  }

  &:last-child::before {
    display: none;
  }

  &__bubble {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $primary-btn-text-color;
    background-color: $primary-color;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    z-index: 1;

    svg {
      width: 13px;
      height: 10px;
    }

    @include xga() {
      display: none;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: $default-label-color;
    text-align: center;

    @include xga() {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;

      background-color: $primary-color;
      color: $primary-btn-text-color;
      border-radius: 6px;
      padding: 10px 17px;
      width: 351px;
    }

    & > .new-job-step-indicator__checkmark {
      display: none;

      @include xga() {
        display: initial;
        width: 12px;
        height: 9px;
        margin-right: 26px;
      }
    }

    &--completed {
      @include xga() {
        background-color: $secondary-background-color;
        color: $default-label-color;
        opacity: 1;
        font-weight: 500;

        i {
          color: $primary-color;
        }
      }
    }
  }

  &--disabled {
    cursor: initial;
  }

  &:first-child,
  &:nth-child(2) {
    cursor: pointer;
  }

  &--disabled > #{&}__bubble {
    background-color: $secondary-disabled-color;
  }

  &--disabled > #{&}__title {
    opacity: 0.5;
    background-color: $secondary-background-color;
    color: $default-label-color;

    @include xga() {
      i {
        color: $tertiary-disabled-color;
      }
    }
  }

  &__checkmark {
    font-size: 0.75rem;

    @include xga() {
      font-size: 0.625rem;
      margin-right: 26px;
    }
  }
}
