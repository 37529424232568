@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.hr-jobs {
  .jobs {
    $tab-margin-x: 22px;
    $tab-padding-x: 16px;

    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 6px 18px rgba(62, 56, 106, 0.06);

    background-color: $secondary-background-color;

    &__navigation {
      width: 100%;
      display: flex;
      overflow: hidden;
      padding: 16px 0 0;
      position: relative;
      justify-content: space-between;

      border-bottom: 1px solid $border-default-color;

      @include xga {
        justify-content: unset;
      }
    }

    &__tab {
      cursor: pointer;
      font-weight: 500;
      padding: 0 0 14px;
      font-size: 0.875rem;
      text-align: center;
      position: relative;
      line-height: 1.25rem;
      transition: 0.4s color;
      color: $description-color;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      padding: 0 $tab-padding-x 14px;

      @include xga {
        margin: 0 $tab-margin-x;
      }

      &::after {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        transition: 0.2s transform;
        transform: translateY(2px);

        background-color: $primary-color;
      }

      &--active {
        color: $primary-color;

        &::after {
          transform: translateY(0);
        }
      }

      &:hover {
        color: $primary-color;
      }
    }

    &__tiles {
    }

    &__actions {
      display: none;

      @include xga {
        display: block;
        text-align: right;
        padding: 64px 38px 16px 0;
      }
    }

    &__button {
      border: none;
      cursor: pointer;
      font-weight: 500;
      margin: 0 0 0 12px;
      border-radius: 6px;
      padding: 15px 35px;
      line-height: 1.5rem;
      font-size: 1.125rem;
      display: inline-block;

      color: $primary-btn-text-color;
      background-color: $primary-color;

      &--secondary {
        color: $primary-color;
        background-color: $secondary-btn-color;
      }
    }
  }
}
