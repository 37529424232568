@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.search-field {
  position: relative;

  &__container {
    height: 44px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    border: 1px solid $secondary-border-color;
    background-color: $default-background-color;
  }

  &__clear {
    display: none;

    &--visible {
      display: initial;
      color: $secondary-icon-color;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    padding: 12px;
    border: none;
    background-color: $default-background-color;
  }

  &__submit {
    display: none;

    &--visible {
      height: 100%;
      border: none;
      display: flex;
      font-size: 1rem;
      cursor: pointer;
      padding: 0 12px;
      border-radius: 2px;
      align-items: center;

      color: $secondary-text-color;
      background-color: $primary-color;
    }
  }
}

.search-modal {
  display: none;

  &--visible {
    display: initial;
    position: absolute;
    background-color: $secondary-background-color;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 3px 3px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 4px;
    font-weight: 300;
    font-size: 0.875rem;
    color: $default-label-color;
  }

  &__container {
    padding: 12px 13px 18px;
    border-top: 1px solid $tertiary-border-color;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      padding: 10px 13px 12px;
    }
  }

  &__history {
    ul {
      display: flex;
      cursor: pointer;
      margin: 0 0 8px;

      li {
        font-size: 1rem;
        color: $default-label-color;
        margin: 0 0 0 10px;

        &:last-child {
          color: $secondary-description-color;
          font-weight: 300;
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__recommendation,
  &__saved-searches {
    ul {
      li {
        margin: 0 0 9px 10px;

        color: $primary-blue;
        cursor: pointer;

        span {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__list-element {
    margin-bottom: 9px;
    font-size: 1rem;
    cursor: pointer;

    i {
      margin-right: 10px;
    }

    span {
      color: $default-label-color;
    }

    &:last-child {
      margin-bottom: 7px;
    }
  }

  &__saved-searches {
    ul li {
      display: flex;
      justify-content: space-between;
    }
  }

  &__list-actions {
    color: $secondary-icon-color;

    i {
      cursor: pointer;
      font-size: 0.938rem;

      &:first-child {
        margin-right: 14px;
      }

      &:last-child {
        margin-right: 3px;
      }
    }
  }

  &__icon {
    font-size: 1rem;
  }

  ul {
    list-style: disc inside;

    li::marker {
      margin-right: 11px;
      font-size: 10px;
    }
  }

  i {
    color: $primary-icon-color;
  }
}
