@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

$colors: $primary-text-green, $primary-blue, $primary-dark-blue;

.your-benefits-tile {
  position: relative;
  background-color: $default-tile-color;
  border-radius: 8px;
  padding: 27px 11px;

  &__content {
    display: flex;
    line-height: 22px;
    font-size: 1rem;
    gap: 0 4px;
  }

  &__value {
    color: $primary-color;
  }

  &__description {
    color: $default-label-color;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 7px;
    max-width: 100%;
    border-radius: 6px;
  }

  @for $i from 2 through 4 {
    &:nth-child(#{$i}) {
      .your-benefits-tile__progress {
        background-color: nth($colors, $i - 1);
      }
    }
  }
}
