@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.step-publish-benefits {
  &__label {
    font-size: 14px;
    color: $primary-description-color;
  }

  &__title {
    display: none;

    @include xga() {
      display: block;
      border-bottom: 1px solid $border-default-color;
      padding: 0 13px 23px 39px;
      margin: 24px 0 32px;
    }
  }

  &__header {
    font-weight: 500;
    font-size: 28px;
    color: $default-label-color;
  }

  &__container {
    margin-bottom: 48px;

    @include xga() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 0 21px 48px;
    }
  }
}
