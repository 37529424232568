@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.base-commission {
  background-color: $secondary-background-color;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $primary-color;

    @include xga() {
      border-bottom: none;
      padding: 0 40px;
    }
  }

  &__button {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    color: $tertiary-inactive-color;
    border-radius: 5px 5px 0 0;
    padding: 3px 7px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include xga() {
      padding: 0;
      width: max-content;
      text-align: end;
      overflow: visible;
    }

    span {
      z-index: 2;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      letter-spacing: -0.2px;
      transition: 0.2s color;

      @include xga() {
        font-weight: 400;
        background-color: $primary-card-background;
        font-size: 1.5rem;
      }
    }

    &::after {
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.4s transform;
      border-radius: 4px 4px 0 0;
      background-color: $primary-light-grey;

      transform: translateY(100%);

      @include xga {
        display: none;
      }
    }

    &--active {
      border-bottom: none;

      @include xga() {
        background-color: $secondary-background-color;

        & > .base-commission__arrow {
          transform: rotate(0) translate(0, 0);
        }
      }

      &::after {
        transform: translateY(0);
      }

      span {
        color: $primary-color;
      }
    }
  }

  &__arrow {
    left: -22px;
    width: 20px;
    height: 26px;
    bottom: -20px;
    display: none;
    position: absolute;
    transition: 0.2s all;
    transform: rotate(90deg) translate(-22px, -28px);

    &--right {
      left: unset;
      right: -22px;
      transform: rotate(-90deg) translate(22px, -28px);
    }

    @include xga {
      display: block;
    }
  }

  &__divider {
    font-size: 1.5rem;
    line-height: 1.5rem;

    color: $divider-color;
  }

  &__content {
    overflow: hidden;
    margin: 22px 0 0;
    border: 1px solid #ecebf2;
    box-shadow: 0 6px 18px rgba(62, 56, 106, 0.03);
    border-radius: 6px;
    padding: 78px 12px 8px;

    @include xga() {
      margin: 10px 0 0;
      padding: 96px 20% 0;
    }
  }

  &__slider {
    display: flex;
    position: relative;
    padding: 0 0 102px;
    flex-direction: row;

    input {
      color: black;
    }

    @include xga {
      padding: 0 0 98px;
    }
  }

  &__slider-bubble {
    top: 30px;
    left: 11px;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    line-height: 1.5rem;
    width: calc(100% - 22px);
    transform: translateX(calc(-50% + var(--progress)));

    display: flex;
    justify-content: center;
    color: $primary-color;

    div {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $primary-color;
    }
  }

  &__slider-input {
    width: 100%;
    display: flex;
    margin: 0 4px;
    position: relative;
    align-items: center;

    input[type='range'] {
      margin: 0;
      z-index: 3;
      height: 8px;
      appearance: none;
      position: relative;
      border-radius: 15px;

      background-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    input[type='range']::-webkit-slider-thumb {
      width: 22px;
      height: 22px;
      appearance: none;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      box-shadow: 0px 1px 6px #ced3e8;

      background-color: $primary-color;
    }
  }

  &__track {
    top: 8px;
    left: 0;
    z-index: 1;
    height: 8px;
    width: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 15px;

    background-color: $tertiary-inactive-color;
  }

  &__progress {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 15px;
    transform: translateX(calc(-100% + var(--progress)));

    background-color: $primary-color;
  }

  &__range-boundary {
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include xga {
      font-size: 1.5rem;
      letter-spacing: -0.2px;
    }

    color: $default-label-color;
  }

  &__description {
    font-weight: 300;
    padding: 0 76px 0 0;
    line-height: 1.5rem;
    letter-spacing: -0.2px;

    color: $description-color;

    @include xga() {
      padding: 0 0 126px;
      text-align: center;
      font-size: 1.125rem;
    }
  }
}
