@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.invoice-card {
  padding: 14px 21px 17px 16px;
  display: flex;
  flex-direction: column;
  gap: 11px 0;
  border-radius: 4px;
  border: 1px solid $border-default-color;
  cursor: pointer;

  @include xga() {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    padding: 11px 13px;
    align-items: center;
  }

  &__field {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-weight: 300;
    color: $label-lighter;
    font-size: 0.875rem;

    @include xga() {
      display: none;
    }
  }

  &__value {
    font-size: 0.875rem;
    color: $description-color;

    &--number {
      font-weight: 500;
    }

    &--status {
      position: relative;
      font-weight: 500;
      color: $secondary-text-color;
      border-radius: 32px;
      padding: 2px 10px 4px 22px;

      &::before {
        content: '';
        background-color: $primary-card-background;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        top: 11px;
        left: 11px;
      }
    }

    &--pending {
      background-color: $button-color-linkedin;
    }

    &--paid {
      background-color: $secondary-green;
    }

    &--canceled {
      background-color: $status-canceled-color;
    }
  }
}
