.app-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

// ---old stuff

.profile-status {
  border-radius: 3px;
  font-size: 0.8rem;
  a,
  span {
    font-size: 0.8rem;
  }
}

html {
  overflow-y: auto;
  background: $default-background-color;
}

.rendered-link {
  color: $primary-color !important;
  &:hover {
    color: $primary-lila !important;
  }
}

a {
  color: $primary-text-color;

  &:hover {
    color: $primary-text-color;
  }
}

.logout-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.dot-divider {
  content: '\A';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #5a5a5a;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

.color-light-blue {
  color: $light-blue;
}

input[type='range'] {
  cursor: pointer;
  margin: 3px;
  width: 100%;
}

.range-slider-field {
  width: 100%;
}

.content-block {
  background-color: $default-background-color;
}

*:focus {
  outline: none;
}

.text-uppercase {
  text-transform: capitalize;
}

.file-name {
  border: none;
}

.columns {
  margin: 0px;
}

.normal-line-height {
  line-height: initial;
}

.stream-padding {
  padding: calc(3vh) 0px;
}

.has-border-radius {
  border-radius: $block-border-radius;
}

.scroll-top-block {
  position: fixed;
  bottom: 60px;
  right: 10px;
}

.fullname-title {
  font-size: 23px;
  font-weight: 600;
}

.box-404 {
  display: flex;
  width: calc(80wh);
  min-height: calc(80vh);
  justify-content: center;
  align-items: center;
}

.margin-auto {
  margin: 0px auto;
}

.skill-block {
  height: 30px;
  overflow: hidden;
}

.sticky-search-header {
  position: sticky;
  top: 59px;
  z-index: 15;
  background-color: white;
}

.fake-image {
  .rating-star-block {
    right: 30%;
  }
}

.is-size-1 {
  line-height: normal;
}

.landing-header-block {
  .navbar-link.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  a.navbar-item.is-active,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover {
    color: white !important;
  }
}

.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  color: inherit !important;
}
