@import 'src/ui/scss/Colors';
@import 'src/ui/scss/responsiveness';

.userTourTooltip {
  font-size: 0.8em;
  background-color: white;
  padding: 14px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 300;

  @include mobile {
    font-size: 1em;
  }

  &__title {
    font-weight: 500;
    margin-bottom: 1rem;
    span {
      position: relative;
      z-index: 1;
      img {
        max-width: 30px;
        position: absolute;
        right: -23px;
        top: -5.5px;
        z-index: -1;
      }
    }
  }

  &__content {
    min-width: calc(100vw - 28px);
    max-width: calc(100vw - 28px);
    @include mobile {
      min-width: 80vw;
    }
    @include largemobile {
      min-width: 60vw;
    }
    @include tablet {
      min-width: 500px;
      max-width: $media-tablet-min - 28px;
    }
    p:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    a {
      color: $primary-link-color;
      &:hover,
      &:active,
      &:focus {
        color: darken($primary-link-color, 15);
      }
    }
  }

  &__footer {
    margin-top: 20px;
    overflow: auto;
  }

  &__footerMainButtons {
    float: right;
  }

  &__button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px 20px;
    line-height: 1rem;
    margin: 0 6px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.isPrimary {
      color: $primary-btn-text-color;
      background-color: $primary-color;
    }

    &.isSecondary {
      color: $primary-color;
      background-color: $secondary-btn-color;
    }

    &.isLink {
      color: $primary-color;
      background-color: transparent;
      font-weight: normal;
      padding: 10px 0;
      margin-right: 30px;
    }

    i {
      font-size: 0.8rem;

      &.spires-icon-arrow-forward {
        padding-left: 4px;
      }

      &.spires-icon-arrow-back {
        padding-right: 4px;
      }
    }
  }

  ul {
    list-style: disc inside;
  }

  strong {
    font-weight: 500;
  }

  .userTourTooltip__progress {
    display: flex;
    align-items: stretch;
    gap: 2px;
    margin-top: 14px;
  }

  .userTourTooltip__progressItem {
    flex: 1;
    height: 3px;
    background: $secondary-btn-color;
    &.isDone {
      background: $primary-color;
    }
  }
}
