@import 'src/ui/scss/responsiveness';
@import 'src/ui/scss/Colors';

.contacts {
  &--hide-list-mobile {
    .contact-list {
      display: none;

      @include wxga() {
        display: initial;
      }
    }
  }

  @include wxga() {
    display: grid;
    grid-template-columns: 372px 1fr;
  }
}
